import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import "./profile.css"
import { useAccount } from "wagmi";
import useWallet from "../../hooks/useWallet";
import { fetchUserInfo, updateUser } from "../../api";
import showSwal from "../../utils/showSwal";

export const EditProfile = () => {
    const navigate = useNavigate();
    const { address, isConnected } = useAccount();
    const { userSession, handelConectWallet } = useWallet();
    const [displayName, setDisplayName] = useState("");
    const [bio, setBio] = useState("");
    const [websiteLink, setWebsiteLink] = useState("");
    const [buttonLoading, setButtonLoading] = useState(false);

    useEffect(() => {
        const asyncFn = async () => {
            if (!isConnected || !userSession || userSession.address.toLowerCase() !== address.toLowerCase())
                await handelConectWallet();
            const userInfo = await fetchUserInfo({ address });
            if (userInfo) {
                setDisplayName(userInfo?.name || "");
                setBio(userInfo?.bio || "");
                setWebsiteLink(userInfo?.websiteLink || "");
            }
        };
        asyncFn();
    }, [isConnected, userSession, address]);

    const updateProfile = async () => {
        setButtonLoading(true);
        try {
            if (!isConnected || !userSession || userSession.address.toLowerCase() !== address.toLowerCase())
                await handelConectWallet();
            const isUpdated = await updateUser({ name: displayName, bio, websiteLink });
            if (isUpdated) {
                showSwal("Successfully Updated.", "success");
                navigate("/profile");
            }
        } catch (err) {
            console.log(err);
            showSwal("Error happened while processing.", "warning");
        }
        setButtonLoading(false);
    };

    const clearAll = () => {
        setDisplayName("");
        setBio("");
        setWebsiteLink("");
    };

    return (
        <div className="profile-edit">
            <div className="container">
                <h2 className="text-start mb-5">edit profile</h2>
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="text-start mb-4">account info</h4>
                        <div>
                            <label>DISPLAY NAME</label>
                            <input value={displayName} onChange={(e) => setDisplayName(e.target.value)} type="text" className="form-control" placeholder="Enter your display name" maxLength={15} />
                        </div>
                        <div className="mt-4">
                            <label>BIO</label>
                            <textarea value={bio} onChange={(e) => setBio(e.target.value)} type="text" className="form-control" placeholder="About yourself in a few wards" />
                        </div>
                        <h4 className="text-start mt-4 mb-4">social</h4>
                        <div className="mt-4">
                            <label>PORTFOLIO OR WEBSITE</label>
                            <input value={websiteLink} onChange={(e) => setWebsiteLink(e.target.value)} type="text" className="form-control" placeholder="Enter URL" />
                        </div>
                        {/* <div className="mt-4">
                            <label></label>
                            <input type="text" class="form-control" placeholder="Enter URL" />
                        </div> */}
                        <div className="button-group d-flex align-items-center justify-content-center mt-5">
                            <button className="buy-btn" onClick={() => updateProfile()} disabled={buttonLoading || displayName === ""}>UPDATE PROFILE</button>
                            <button className="offer-btn" onClick={() => { navigate("/profile") }} disabled={buttonLoading}>CANCEL</button>
                            <div onClick={() => clearAll()} className="clear-btn"><i className="fa fa-window-close-o" /> CLEAR ALL</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}