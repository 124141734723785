import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { fetchNfts } from "../../api";
import { ethers } from "ethers";
import ReactLoading from "react-loading";
import { HashLink } from "react-router-hash-link";
import { useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import rarity_icon from "../../assets/images/rarity.webp";

export default function List() {
  const gridSelected = useSelector((state) => state.grid.selected);
  const [fetching, setFetching] = useState(false);
  const [items, setItems] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const [page, setPage] = useState(0);
  const [cursor, setCursor] = useState("");
  const [searchParams] = useSearchParams();
  const [pageLoading, setPageLoading] = useState(false);

  const loader = (
    <button className="load-btn" key={0}>
      <ReactLoading
        className="step-loading"
        type="spinningBubbles"
        color="#ffffff"
        height={30}
        width={30}
      />
      LOAD MORE
    </button>
  );

  const loadNFTs = async (iPage, iCursor, params) => {
    if (fetching) {
      return;
    }
    setFetching(true);
    try {
      const res = await fetchNfts({
        page: iPage === undefined ? page : iPage,
        cursor: iCursor === undefined ? cursor : iCursor,
        searchParams: params
      });
      if (res) {
        if (+res.page === 1) {
          setItems(res.listings);
        } else if (+res.page !== page) {
          setItems((prev) => [...prev, ...res.listings]);
        }
        setPage(res.page);
        setIsEnd(res.isEnd);
        setCursor(res.cursor);
      } else {
        setIsEnd(true);
      }
    } finally {
      setFetching(false);
    }
  };

  const fetchItems = async () => {
    let params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    if (Object.keys(params).length > 0) {
      await loadNFTs(page, cursor, params);
    } else {
      await loadNFTs();
    }
  };

  useEffect(() => {
    const asyncFn = async () => {
      let params = {};
      searchParams.forEach((value, key) => {
        params[key] = value;
      });
      setPageLoading(true);
      if (Object.keys(params).length > 0) {
        setItems([]);
        setCursor("");
        await loadNFTs(0, "", params);
      } else {
        await loadNFTs(0, "");
      }
      setPageLoading(false);
    };
    asyncFn();
  }, [searchParams]);

  if (pageLoading) return loader;
  else
    return items.length > 0 ? (
      <InfiniteScroll
        initialLoad={false}
        loadMore={fetchItems}
        hasMore={!isEnd}
        loader={loader}
      >
        <div
          className={
            (gridSelected === 0 ? "big-grid" : "small-grid") + " nfts-grid"
          }
        >
          {items?.map((item) => {
            return (
              <div className="nft-item" key={item.token_id}>
                <HashLink
                  key={item.token_id}
                  to={`/detail/${item.token_address}/${item.token_id}#`}
                  smooth
                >
                  <div className="overflow-hidden">
                    {item.normalized_metadata?.image &&
                    item.normalized_metadata?.image.includes(".m4v") ? (
                      <video
                        src={
                          item.normalized_metadata.image + "?stream=true" ||
                          "/assets/images/default/" + item.symbol + ".mp4"
                        }
                        controls
                        muted
                        className="nft"
                      />
                    ) : (
                      <img
                        src={
                          item.media?.media_collection?.high?.url ||
                          (!!item.normalized_metadata?.image &&
                            item.normalized_metadata?.image +
                              "?img-width=380") ||
                          "/assets/images/default/" + item.symbol + ".gif"
                        }
                        className="nft"
                        alt="nft"
                      />
                    )}
                  </div>
                  <div className="nft-content">
                    <div className="">
                      <p className="nft-id text-start float-start mb-1 mt-1">
                        {item?.name} #{item?.token_id}
                      </p>
                      <div className="clearfix"></div>
                      {!!item.price && !!item.creatorAddress && (
                        <p className="float-start nft-price mt-1">
                          {ethers.utils.formatEther(item.price)} ETH
                        </p>
                      )}
                      {!!item.rank && (
                        <>
                          <p
                            className="nft-rank float-end d-flex align-items-center"
                            id={"rank" + item.rank}
                          >
                            <img
                              src={rarity_icon}
                              style={{ width: "15px" }}
                              className="me-1"
                              alt="rarity-icon"
                            />
                            {item.rank}
                          </p>
                          <Tooltip
                            anchorId={"rank" + item.rank}
                            place="top"
                            content={"Rarity rank: " + item.rank + " / 8888"}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </HashLink>
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
    ) : (
      <>
        <h4 className="mt-5">No items found for this search</h4>
        <HashLink className="load-btn d-inline-block back-all" to="/">
          Back to all items
        </HashLink>
      </>
    );
}
