import Modal from "react-modal";
import { useAccount, useBalance } from "wagmi";
import { ethers } from "ethers";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { buyItemWeb3 } from "../../web3/listing";
import showSwal from "../../utils/showSwal";
import ReactLoading from "react-loading";
import { updateListing } from "../../api";
import useWallet from "../../hooks/useWallet";

const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;

export default function CheckoutModal({
  isModalOpen,
  closeModal,
  nftData,
  loadData
}) {
  const { address, isConnected } = useAccount();
  const { handleConnectWallet, userSession, userInfo } = useWallet();
  const { nftAddress, tokenId } = useParams();
  const { data: balance } = useBalance({ address });

  const [buttonLoading, setButtonLoading] = useState(false);

  const purchaseItem = async () => {
    if (
      !isConnected ||
      !userSession ||
      userSession.address.toLowerCase() !== address.toLowerCase()
    ) {
      await handleConnectWallet();
    }
    setButtonLoading(true);
    try {
      const isBuyItem = await buyItemWeb3(
        address,
        nftAddress,
        tokenId,
        tokenAddress,
        nftData.owner_of,
        nftData?.price
      );
      if (isBuyItem && isBuyItem.success === true) {
        const isUpdateListing = await updateListing({
          isPurchase: true,
          nftAddress,
          tokenId,
          price: nftData?.price,
          payMethod: nftData?.payMethod,
          payToken: nftData?.payToken,
          transaction: isBuyItem.hash,
          deadLine: nftData?.deadline,
          creatorAddress: nftData.owner_of,
          live: false
        });
        if (isUpdateListing) {
          showSwal("Successfully purchased!", "success");
          closeModal();
          await loadData();
        }
      }
    } catch (err) {
      console.log(err);
      if (err.code === 4001) showSwal(err.message, "warning");
      else showSwal("Error happened while processing.", "warning");
    }
    setButtonLoading(false);
  };

  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={isModalOpen && isConnected && !!userSession}
      onRequestClose={() => {
        !buttonLoading && closeModal();
      }}
      contentLabel="Checkout Modal"
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "overlay-after",
        beforeClose: "overlay-before"
      }}
      className={{
        base: "content-base",
        afterOpen: "content-after",
        beforeClose: "content-before"
      }}
      closeTimeoutMS={500}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="modal-title2">Checkout</h2>
        <div className="close" onClick={closeModal}>
          <i className="fa close-icon">&#xf00d;</i>
        </div>
      </div>
      <p className="modal-description text-white">
        You are about to purchase #{tokenId} from
        {userInfo?.name || nftData?.owner_of?.substring(2, 8).toUpperCase()}
      </p>

      <div
        className={
          balance?.formatted < ethers.utils.formatEther(nftData.price)
            ? "warning position-relative"
            : "position-relative"
        }
      >
        <input
          type="text"
          placeholder="Enter bid"
          value={!!nftData.price && ethers.utils.formatEther(nftData.price)}
          className="modal-address m-0 p-2"
          readOnly
        />
        <p className="count unit me-2">ETH</p>
      </div>
      {balance?.formatted < ethers.utils.formatEther(nftData.price) && (
        <p className="red">
          <i className="fa fa-exclamation-triangle"></i>You don't have enough
          ETH
        </p>
      )}
      <div className="row-item">
        <p className="modal-description m-0">Your balance</p>
        <p className="count">{(+balance?.formatted).toFixed(4)} ETH</p>
      </div>
      {/* <div className="row-item">
        <p className="modal-description m-0">Service fee</p>
        <p className="count">0 ETH</p>
      </div> */}
      <div className="row-item">
        <p className="modal-description m-0">Total pay amount</p>
        <p className="count">
          {!!nftData.price && ethers.utils.formatEther(nftData.price)} ETH
        </p>
      </div>
      <button
        className="modal-ok-btn d-flex align-items-center justify-content-center"
        disabled={
          balance?.formatted < ethers.utils.formatEther(nftData.price) ||
          buttonLoading
        }
        onClick={() => purchaseItem()}
      >
        {buttonLoading ? (
          <ReactLoading
            className="step-loading"
            type="spinningBubbles"
            color="#ffffff"
            height={20}
            width={20}
          />
        ) : (
          "I UNDERSTAND, CONTINUE"
        )}
      </button>
      <button className="modal-cancel-btn" onClick={closeModal}>
        CANCEL
      </button>
    </Modal>
  );
}
