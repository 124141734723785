import { useState, useEffect } from "react";

const useCopyClip = () => {
  const [copyStatus, setCopyStatus] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopyStatus(true);
    setTimeout(() => {
      setCopyStatus(false);
    }, 3000); // 5 second delay
  };

  return { copyToClipboard, copyStatus };
};

export default useCopyClip;
