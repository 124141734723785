import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAccount } from "wagmi";
import dots from "../../assets/images/dots.png";
import circle_dollar from "../../assets/images/circle-dollar.png";
import transfer from "../../assets/images/transfer.png";
import report from "../../assets/images/report.png";
import {
  BurnModal,
  ReportModal,
  TransferModal,
  ChangePriceModal
} from "../modal";
import { ethers } from "ethers";
import { HashLink } from "react-router-hash-link";
import useWallet from "../../hooks/useWallet";
import rarity_icon from "../../assets/images/rarity.webp";
import { refreshMetadata, updateUser } from "../../api";
import showSwal from "../../utils/showSwal";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import useCopyClip from "../../hooks/useCopyClip";

export default function Info({ nftData, collectionData, loadData, isBanned }) {
  const { address, isConnected } = useAccount();
  const { handleConnectWallet, userSession, userInfo } = useWallet();
  const { nftAddress, tokenId } = useParams();
  const { copyStatus, copyToClipboard } = useCopyClip();

  const [transferModalIsOpen, setTransferModalIsOpen] = useState(false);
  const [burnModalIsOpen, setBurnModalIsOpen] = useState(false);
  const [reportModalIsOpen, setReportModalIsOpen] = useState(false);
  const [changePriceModalIsOpen, setChangePriceModalIsOpen] = useState(false);
  const [likes, setLikes] = useState([]);
  const [isLike, setIsLike] = useState(false);

  useEffect(() => {
    if (userInfo && userInfo.likes) {
      setLikes(userInfo.likes);
      setIsLike(
        !!userInfo.likes.find(
          (e) =>
            e.nftAddress.toLowerCase() === nftAddress.toLowerCase() &&
            +e.tokenId === +tokenId
        )
      );
    } else {
      setIsLike(false);
      setLikes([]);
    }
  }, [userInfo, nftAddress, tokenId]);

  const handelLike = async () => {
    if (
      !userSession ||
      userSession.address.toLowerCase() !== address.toLowerCase()
    ) {
      await handleConnectWallet();
    }
    try {
      let like_array = likes;
      if (isLike) {
        like_array = like_array.filter(
          (e) =>
            !(
              e.nftAddress.toLowerCase() === nftAddress.toLowerCase() &&
              +e.tokenId === +tokenId
            )
        );
      } else {
        like_array = [
          ...like_array,
          { nftAddress: nftAddress.toLowerCase(), tokenId: +tokenId }
        ];
      }
      const isUpdatedUser = await updateUser({ likes: like_array });
      if (isUpdatedUser) {
        setLikes(like_array);
        setIsLike(!isLike);
      }
    } catch (err) {
      console.log(err);
      showSwal("Error happened while processing.", "warning");
    }
  };

  const handleChangePrice = async () => {
    if (
      !isConnected ||
      !userSession ||
      userSession.address.toLowerCase() !== address.toLowerCase()
    ) {
      await handleConnectWallet();
    }
    setChangePriceModalIsOpen(true);
  };

  const handleTransfer = async () => {
    if (
      !isConnected ||
      !userSession ||
      userSession.address.toLowerCase() !== address.toLowerCase()
    ) {
      await handleConnectWallet();
    }
    setTransferModalIsOpen(true);
  };

  const closeModal = () => {
    setBurnModalIsOpen(false);
    setReportModalIsOpen(false);
  };

  const handleRefresh = async () => {
    try {
      const isRefreshed = await refreshMetadata({ nftAddress, tokenId });
      if (isRefreshed) {
        showSwal(
          "We've queued this item for an update! Check back in a minute...",
          "success"
        );
      }
    } catch (err) {
      showSwal("Error happened while processing.", "warning");
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <HashLink
            className="text-decoration-none"
            smooth
            to={"/?collection=" + collectionData?.shortName}
          >
            <h5>{collectionData?.name}</h5>
          </HashLink>
          <p className="detail-id">
            {nftData?.name} #{tokenId}
          </p>
          <p className="detail-owner">
            Owned by&nbsp;
            {nftData.owner_of?.toLowerCase() === address?.toLowerCase() ? (
              <HashLink className="text-decoration-none" smooth to="/profile#">
                You
              </HashLink>
            ) : (
              <HashLink
                className="text-decoration-none"
                smooth
                to={"/profile/" + nftData.owner_of + "#"}
              >
                {nftData?.ownerName ||
                  nftData?.owner_of?.substring(2, 8).toUpperCase()}
              </HashLink>
            )}
          </p>
          {!!nftData?.rank && (
            <>
              <p
                className="nft-rank d-inline-flex align-items-center mt-4"
                id={"rank" + nftData?.rank}
                style={{ fontSize: "16px" }}
              >
                <img
                  src={rarity_icon}
                  style={{ width: "25px" }}
                  className="me-1"
                  alt="rarity-icon"
                />
                {nftData?.rank}
              </p>
              <Tooltip
                anchorId={"rank" + nftData?.rank}
                place="top"
                content={"Rarity rank: " + nftData?.rank + " / 8888"}
              />
            </>
          )}
        </div>
        <div className="d-flex gap-sm-3 gap-2">
          <div
            className="icon-circle"
            id="share"
            onClick={() => {
              copyToClipboard(
                window.location.origin + "/detail/" + nftAddress + "/" + tokenId
              );
            }}
          >
            <i className="fa-solid fa-arrow-up-from-bracket"></i>
          </div>
          <Tooltip
            anchorSelect="#share"
            place="top"
            content={copyStatus ? "Copied!" : "Share"}
          />
          {address && (
            <>
              <div
                id="like"
                className={isLike ? "icon-circle active" : "icon-circle"}
                onClick={() => handelLike()}
              >
                <i className="fa-solid fa-heart"></i>
              </div>
              <Tooltip
                anchorSelect="#like"
                place="top"
                content={isLike ? "Unfavorite" : "Favorite"}
              />
            </>
          )}
          {!isBanned && (
            <>
              <div className="dropdown">
                <div
                  className="icon-circle"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="dots" />
                </div>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {address &&
                    address.toLowerCase() ===
                      nftData?.owner_of?.toLowerCase() && (
                      <>
                        {!!nftData.price && (
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() => handleChangePrice()}
                            >
                              <img src={circle_dollar} alt="dollar" /> Change
                              price
                            </div>
                          </li>
                        )}
                        <li>
                          <div
                            className="dropdown-item"
                            onClick={() => handleTransfer()}
                          >
                            <img src={transfer} alt="dollar" /> Transfer Token
                          </div>
                        </li>
                        {/* <li>
                      <div
                        className="dropdown-item"
                        onClick={() => setBurnModalIsOpen(true)}
                      >
                        <img src={filled_close_circle} alt="dollar" /> Burn
                        token
                      </div>
                    </li> */}
                      </>
                    )}
                  <li>
                    <div
                      className="dropdown-item"
                      onClick={() => handleRefresh()}
                    >
                      <i className="fa fa-refresh" /> Refrech metadata
                    </div>
                  </li>
                  {/* <li>
                <div
                  className="dropdown-item"
                  onClick={() => setReportModalIsOpen(true)}
                >
                  <img src={report} alt="dollar" /> Report
                </div>
              </li> */}
                </ul>
              </div>
              <Tooltip
                anchorSelect="#dropdownMenuButton1"
                place="top"
                content="More"
              />
            </>
          )}
        </div>
      </div>
      {!!nftData.price && (
        <>
          {/* <div className="sales-end">Sales ends March 28, 2023 at 1:00 PM</div> */}
          <p className="detail-owner mt-5">Current Price</p>
          <div className="d-flex gap-3 align-items-center my-4">
            <h4 className="eth-price m-0">
              {ethers.utils.formatEther(nftData.price)} ETH
            </h4>
            {/* <h6 className="usd-price m-0">$4,429.87</h6> */}
          </div>
          <ChangePriceModal
            isModalOpen={changePriceModalIsOpen}
            setModalOpen={setChangePriceModalIsOpen}
            nftData={nftData}
            loadData={loadData}
            serviceFee={collectionData?.creatorFee}
          />
        </>
      )}
      <BurnModal
        isModalOpen={burnModalIsOpen}
        setBurnModalIsOpen={setBurnModalIsOpen}
      />
      <ReportModal
        reportModalIsOpen={reportModalIsOpen}
        closeModal={closeModal}
      />
      <TransferModal
        isModalOpen={transferModalIsOpen}
        setModalOpen={setTransferModalIsOpen}
        nftData={nftData}
        loadData={loadData}
      />
    </>
  );
}
