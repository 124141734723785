import mbhAxios from "./api";
import showSwal from "../utils/showSwal";

export const updateListing = async (param) => {
  try {
    const response = await mbhAxios.post("updateListing", param);
    if (response.data && response.data.success === true) {
      return response.data.success;
    } else if (response.data.success === false) {
      showSwal(
        `Error happened while processing. ${response.data.message}`,
        "warning"
      );
    }
    return;
  } catch (err) {
    showSwal(err.message, "error");
    return;
  }
};
