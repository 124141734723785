import mbhAxios from "./api";
import showSwal from "../utils/showSwal";

export const fetchOffers = async ({ ownerAddress, nftAddress, tokenId }) => {
  try {
    const response = await mbhAxios.post("getOffers", {
      ownerAddress,
      nftAddress,
      tokenId,
    });
    if (response.data && response.data.success === true) {
      return response.data.offers;
    }
    return;
  } catch (err) {
    showSwal(err.message, "error");
    return;
  }
};

export const createOffer = async ({
  nftAddress,
  tokenId,
  payToken,
  price,
  signature,
}) => {
  try {
    const response = await mbhAxios.post("createOffer", {
      nftAddress,
      tokenId,
      payToken,
      price,
      signature,
    });
    if (response.data && response.data.success === true) {
      return response.data.success;
    } else if (response.data.success === false) {
      showSwal(
        `Error happened while processing. ${response.data.message}`,
        "warning"
      );
    }
    return null;
  } catch (err) {
    showSwal(err.message, "error");
    return;
  }
};

export const acceptOffer = async ({ offerId, address }) => {
  try {
    const response = await mbhAxios.post("acceptOffer", { offerId, address });
    if (response.data && response.data.success === true) {
      return response.data;
    } else if (response.data.success === false) {
      showSwal(
        `Error happened while processing. ${response.data.message}`,
        "warning"
      );
    }
    return null;
  } catch (err) {
    showSwal(err.message, "error");
    return;
  }
};

export const cancelOffer = async ({ offerId, signature }) => {
  try {
    const response = await mbhAxios.post("cancelOffer", { offerId, signature });
    if (response.data && response.data.success === true) {
      return response.data.success;
    } else if (response.data.success === false) {
      showSwal(
        `Error happened while processing. ${response.data.message}`,
        "warning"
      );
    }
    return null;
  } catch (err) {
    showSwal(err.message, "error");
    return;
  }
};

export const gotOffer = async ({
  nftAddress,
  tokenId,
  transaction,
  signature,
}) => {
  try {
    const response = await mbhAxios.post("gotOffer", {
      nftAddress,
      tokenId,
      transaction,
      signature,
    });
    if (response.data && response.data.success === true) {
      return response.data.success;
    } else if (response.data.success === false) {
      showSwal(
        `Error happened while processing. ${response.data.message}`,
        "warning"
      );
    }
    return null;
  } catch (err) {
    showSwal(err.message, "error");
    return;
  }
};
