import Modal from "react-modal";
import ReactLoading from "react-loading";
import accept from "../../assets/images/accept.png";

export default function AcceptStepModal({
  isModalOpen,
  startTransaction,
  isLoading,
  closeModal,
}) {
  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={isModalOpen}
      onRequestClose={() => {
        !isLoading && closeModal();
      }}
      contentLabel="Accept Start Modal"
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "overlay-after",
        beforeClose: "overlay-before",
      }}
      className={{
        base: "content-base",
        afterOpen: "content-after",
        beforeClose: "content-before",
      }}
      closeTimeoutMS={500}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="modal-title2">Follow Steps</h2>
        <div className="close" onClick={closeModal}>
          <i className="fa close-icon">&#xf00d;</i>
        </div>
      </div>
      <div className="accept-bid">
        <img src={accept} alt="accept" className="accept-icon" />
        <div>
          <p className="modal-description mt-0 mb-1 text-white">Accept bid</p>
          <p className="sub-description m-0">
            Send transaction with your wallet
          </p>
        </div>
      </div>
      <button
        className="modal-ok-btn"
        onClick={startTransaction}
        disabled={isLoading}
      >
        {isLoading ? (
          <ReactLoading
            className="step-loading m-auto"
            type="spinningBubbles"
            color="#ffffff"
            height={20}
            width={20}
          />
        ) : (
          "START NOW"
        )}
      </button>
    </Modal>
  );
}
