import { useState } from "react";
import { useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";

export default function About({ nftData, collectionData }) {
  const { tokenId } = useParams();
  const [showDetail, setShowDetail] = useState([1, 0, 0]);

  const handleStatusFilter = (index) => {
    const nextCounters = showDetail.map((c, i) => {
      if (i === index - 1) {
        if (c) {
          c = 0;
        } else {
          c = 1;
        }
        return c;
      } else {
        return c;
      }
    });
    setShowDetail(nextCounters);
  };

  return (
    <div className="detail-img d-flex align-items-center justify-content-center flex-column">
      <div className="position-relative">
        {nftData.normalized_metadata.image &&
        nftData.normalized_metadata.image.includes(".m4v") ? (
          <video
            src={
              nftData.normalized_metadata.image + "?stream=true" ||
              "/assets/images/default/" + nftData.symbol + ".mp4"
            }
            autoPlay
            controls
            muted
          />
        ) : (
          <img
            src={
              (!!nftData.normalized_metadata.image &&
                nftData.normalized_metadata.image + "?img-width=600") ||
              nftData.media?.media_collection?.high?.url ||
              "/assets/images/default/" + nftData.symbol + ".gif"
            }
            alt="detail avatar"
          />
        )}

        {!!nftData.normalized_metadata.image && (
          <>
            <a
              className="view-original"
              href={nftData.normalized_metadata.image}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-solid fa-up-right-from-square"></i>
            </a>
            <Tooltip
              anchorSelect=".view-original"
              place="top"
              content={"View original media"}
            />
          </>
        )}
      </div>

      <div className="row-info mt-3">
        <div
          onClick={() => handleStatusFilter(1)}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="d-flex align-items-center gap-3">
            <i className="fas fa-address-card"></i>
            <p className="row-info-text">Attributes</p>
          </div>
          <i className="fa-solid fa-angle-down"></i>
        </div>
        <div
          className={
            "filter-values " + (showDetail[0] ? "show-filter" : "hide-filter")
          }
        >
          <div className="row pt-4">
            {nftData?.normalized_metadata.attributes?.map((attribute, key) => (
              <div key={key} className="col-4">
                <div className="trait-item">
                  <p className="trait-type">{attribute.trait_type}</p>
                  <p className="trait-value">{attribute.value}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="row-info">
        <div
          onClick={() => handleStatusFilter(2)}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="d-flex align-items-center gap-3">
            <i className="fas fa-list-alt"></i>
            <p className="row-info-text">Details</p>
          </div>
          <i className="fa-solid fa-angle-down"></i>
        </div>
        <div
          className={
            "details-content filter-values " +
            (showDetail[1] ? "show-filter" : "hide-filter")
          }
        >
          <div className="d-flex algin-items-center justify-content-between">
            <p>Contract Address</p>
            <a
              href={
                collectionData?.scanLink + "address/" + nftData.token_address
              }
              target="_blank"
              rel="noreferrer"
            >
              <p>{` ${nftData.token_address.substring(
                0,
                7
              )}...${nftData.token_address.substring(
                nftData.token_address.length - 4
              )}`}</p>
            </a>
          </div>
          <div className="d-flex algin-items-center justify-content-between">
            <p>Token ID</p>
            <a
              href={nftData.token_uri?.replace(
                "https://ipfs.moralis.io:2053/",
                "https://mbh.mypinata.cloud/"
              )}
              target="_blank"
              rel="noreferrer"
            >
              <p>{tokenId}</p>
            </a>
          </div>
          <div className="d-flex algin-items-center justify-content-between">
            <p>Token Standard</p>
            <p>{nftData?.contract_type}</p>
          </div>
          <div className="d-flex algin-items-center justify-content-between">
            <p>Chain</p>
            <p>{collectionData?.chain}</p>
          </div>
        </div>
      </div>
      <div className="row-info">
        <div
          onClick={() => handleStatusFilter(3)}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="d-flex align-items-center gap-3">
            <i className="fas fa-book-open"></i>
            <p className="row-info-text">About {collectionData?.name}</p>
          </div>
          <i className="fa-solid fa-angle-down"></i>
        </div>
        <div
          className={
            "details-content text-start filter-values " +
            (showDetail[2] ? "show-filter" : "hide-filter")
          }
          dangerouslySetInnerHTML={{ __html: collectionData?.description }}
        />
      </div>
    </div>
  );
}
