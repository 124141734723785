import {
  prepareWriteContract,
  writeContract,
  readContract,
  waitForTransaction,
} from "@wagmi/core";
import showSwal from "../utils/showSwal";
const ethers = require("ethers");

const Market = require("./abi/MBHMarketplace.json");
const MBH = require("./abi/MBH.json");
const WETH = require("./abi/tokenABI.json");

export const approveTokenWeb3 = async (address, tokenAddress, bidAmount) => {
  /* const amount = parseInt(ethers.utils.parseEther(bidAmount)._hex, 16); */
  const approveAmount =
    "115792089237316195423570985008687907853269984665640564039457584007913129639935";

  try {
    const data = await readContract({
      address: tokenAddress,
      abi: WETH.abi,
      functionName: "allowance",
      args: [address, process.env.REACT_APP_MARKET_ADDRESS],
    });
    if (ethers.utils.formatEther(data) < bidAmount) {
      const config = await prepareWriteContract({
        address: tokenAddress,
        abi: WETH.abi,
        functionName: "approve",
        args: [process.env.REACT_APP_MARKET_ADDRESS, approveAmount],
      });
      const { hash } = await writeContract(config);
      const transaction = await waitForTransaction({ hash });
      return { success: true, hash: transaction.transactionHash };
    }
    return { success: true };
  } catch (err) {
    if (err.code !== 4001)
      showSwal(err?.error?.message || err.message, "warning");
    return;
  }
};

export const approveNFTWeb3 = async (address, nftAddress) => {
  try {
    const isApprovedForAll = await readContract({
      address: nftAddress,
      abi: MBH.abi,
      functionName: "isApprovedForAll",
      args: [address, process.env.REACT_APP_MARKET_ADDRESS],
    });
    if (isApprovedForAll) {
      return { success: true };
    }
    const approved = true;
    const config = await prepareWriteContract({
      address: nftAddress,
      abi: MBH.abi,
      functionName: "setApprovalForAll",
      args: [process.env.REACT_APP_MARKET_ADDRESS, approved],
    });
    const { hash } = await writeContract(config);
    const transaction = await waitForTransaction({ hash });
    return { success: true, hash: transaction.transactionHash };
  } catch (err) {
    if (err.code !== 4001)
      showSwal(err?.error?.message || err.message, "warning");
    return;
  }
};

export const acceptOfferWeb3 = async (
  address,
  nftAddress,
  tokenId,
  payToken,
  price,
  offerer,
  signature
) => {
  try {
    const config = await prepareWriteContract({
      address: process.env.REACT_APP_MARKET_ADDRESS,
      abi: Market.abi,
      functionName: "acceptOffer",
      args: [nftAddress, tokenId, payToken, price, offerer, signature],
    });
    const { hash } = await writeContract(config);
    const transaction = await waitForTransaction({ hash });
    return { success: true, hash: transaction.transactionHash };
  } catch (err) {
    if (err.code !== 4001)
      showSwal(err?.error?.message || err.message, "warning");
    return;
  }
};
