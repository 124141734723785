import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactLoading from "react-loading";
import "./detail.css";
import { useAccount } from "wagmi";
import { About, Info, Buyer, Seller, History, Activity } from "../../components/detail";
import { fetchNftData, fetchOffers, fetchActivity } from "../../api";
import useWallet from '../../hooks/useWallet';
import { useNavigate } from 'react-router-dom';

export const Detail = () => {
    const navigate = useNavigate();
    const { address, isConnected } = useAccount();
    const { nftAddress, tokenId } = useParams();
    const [pageLoading, setPageLoading] = useState(false);
    const { fetchWalletBalance } = useWallet();
    const [nftData, setNftData] = useState();
    const [priceHistory, setPriceHistory] = useState([]);
    const [offers, setOffers] = useState([]);
    const [activityData, setActivityData] = useState([]);
    const [collectionData, setCollectiondata] = useState([]);
    const [isBanned, setIsBanned] = useState(false);

    const loadData = async () => {
        setPageLoading(true);
        const response = await fetchNftData({ nftAddress, tokenId });
        if (response) {
            setNftData(response.data);
            setPriceHistory(response.priceHistory);
            setCollectiondata(response.collection);
            setIsBanned(response.isBan);
        } else {
            navigate("/")
        }
        setOffers(await fetchOffers({ ownerAddress: response.data.owner_of, nftAddress, tokenId }));
        setActivityData(await fetchActivity({ nftAddress, tokenId }));
        fetchWalletBalance();
        setPageLoading(false)
    };

    useEffect(() => {
        if (nftAddress && tokenId)
            loadData();
    }, [nftAddress, tokenId]);

    return (
        pageLoading || !nftData ? (
            <div className='mt-5 pt-5 d-flex align-items-center justify-content-center' >
                <ReactLoading className="step-loading" type="spinningBubbles" color="#ffffff" height={80} width={80} />
            </div >
        ) : (
            <>
                {
                    isBanned && (
                        <div className='banned-text'>The purchase and sale of this NFT is not allowed.</div>
                    )
                }
                < div className="detail" >
                    <div className="custom-container">
                        <div className="detail-info row">
                            <div className='col-xl-4 col-lg-5 d-none d-lg-block'>
                                <About nftData={nftData} collectionData={collectionData} />
                            </div>
                            <div className='col-xl-8 col-lg-7'>
                                <div className="detail-content">
                                    <Info nftData={nftData} collectionData={collectionData} loadData={loadData} isBanned={isBanned} />
                                    {!isBanned && (
                                        isConnected ? (nftData.owner_of?.toLowerCase() === address?.toLowerCase() ? (
                                            <Seller nftData={nftData} loadData={loadData} collectionData={collectionData} />
                                        ) : (
                                            <Buyer nftData={nftData} loadData={loadData} collectionData={collectionData} />
                                        )) : (
                                            <Buyer nftData={nftData} loadData={loadData} collectionData={collectionData} />
                                        )
                                    )}

                                    <div className="d-block d-lg-none">
                                        <About nftData={nftData} />
                                    </div>
                                    <History nftData={nftData} collectionData={collectionData} priceHistory={priceHistory} offers={offers} loadData={loadData} />
                                </div>
                            </div>
                        </div>
                        <Activity data={activityData} collectionData={collectionData} />
                        {/* <More /> */}
                    </div>
                </div >
            </>
        )
    );
}