import mbhAxios from "./api";
import showSwal from "../utils/showSwal";

export const fetchCollections = async () => {
  try {
    const response = await mbhAxios.post("getAllCollections");
    if (response.data && response.data.success === true) {
      return response.data.collections;
    }
    return null;
  } catch (err) {
    showSwal(`${err.message}!`, "error");
    return null;
  }
};

export const fetchCollectionDetail = async (params) => {
  try {
    const response = await mbhAxios.post("getCollection", params);
    if (response.data && response.data.success === true) {
      return response.data.collections;
    }
    return null;
  } catch (err) {
    showSwal(`${err.message}!`, "error");
    return null;
  }
};

export const fetchNfts = async (params) => {
  try {
    const response = await mbhAxios.post("getNfts", params);
    if (response.data && response.data.success === true) {
      return response.data;
    } else {
      showSwal(`${response.data.message}!`, "error");
    }
    return null;
  } catch (err) {
    showSwal(`${err.message}!`, "error");
    return null;
  }
};

export const fetchNftData = async ({ nftAddress, tokenId }) => {
  try {
    const response = await mbhAxios.post("getNftById", { nftAddress, tokenId });
    if (response.data && response.data.success === true) {
      return response.data;
    } else {
      showSwal(`${response.data.message}!`, "error");
    }
    return null;
  } catch (err) {
    showSwal(`${err.message}!`, "error");
    console.log(err);
    return;
  }
};

export const burnNFT = async ({ nftAddress, tokenId }) => {
  try {
    const response = await mbhAxios.post("burnNFT", { nftAddress, tokenId });
    if (response.data && response.data.success === true) {
      return response.data.success;
    } else {
      showSwal(`${response.data.message}!`, "error");
    }
    return null;
  } catch (err) {
    showSwal(`${err.message}!`, "error");
    console.log(err);
    return;
  }
};

export const fetchNftByWallet = async ({ address }) => {
  try {
    const response = await mbhAxios.post("getNftsByAddress", { address });
    if (response.data && response.data.success === true) {
      return response.data.data;
    }
    return null;
  } catch (err) {
    showSwal(`${err.message}!`, "error");
    console.log(err);
    return;
  }
};

export const fetchLikesNft = async ({ address }) => {
  try {
    const response = await mbhAxios.post("getLikesNft", { address });
    if (response.data && response.data.success === true) {
      return response.data.data;
    }
    return null;
  } catch (err) {
    showSwal(`${err.message}!`, "error");
    console.log(err);
    return;
  }
};

export const fetchActivity = async ({ nftAddress, tokenId }) => {
  try {
    const response = await mbhAxios.post("getActivityById", {
      nftAddress,
      tokenId
    });
    if (response.data && response.data.success === true) {
      return response.data.activity;
    }
    return;
  } catch (err) {
    showSwal(`${err.message}!`, "error");
    console.log(err);
    return;
  }
};

export const refreshMetadata = async ({ nftAddress, tokenId }) => {
  try {
    const response = await mbhAxios.post("refreshMetadata", {
      nftAddress,
      tokenId
    });
    if (response.data && response.data.success === true) {
      return response.data.success;
    }
    return;
  } catch (err) {
    showSwal(`${err.message}!`, "error");
    console.log(err);
    return;
  }
};
