import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import ReactLoading from "react-loading";
import { approveNFTWeb3 } from "../../web3/offer";
import showSwal from "../../utils/showSwal";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import { listItemWeb3 } from "../../web3/listing";
import { updateListing } from "../../api";
import useWallet from "../../hooks/useWallet";

export default function SellModal({
  isModalOpen,
  setModalOpen,
  loadData,
  serviceFee
}) {
  const { address, isConnected } = useAccount();
  const { handleConnectWallet, userSession } = useWallet();
  const { nftAddress, tokenId } = useParams();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [sellPrice, setSellPrice] = useState(0);

  const listing = async () => {
    setButtonLoading(true);
    try {
      if (
        !isConnected ||
        !userSession ||
        userSession.address.toLowerCase() !== address.toLowerCase()
      ) {
        await handleConnectWallet();
      }
      const isApproveNFT = await approveNFTWeb3(address, nftAddress);
      if (isApproveNFT) {
        const payMethod = 0;
        const payToken = process.env.REACT_APP_TOKEN_ADDRESS;
        const deadLine = process.env.REACT_APP_LISTING_DEADLINE;
        const price = ethers.utils.parseEther(sellPrice).toString(10);
        const isListItem = await listItemWeb3(
          nftAddress,
          tokenId,
          payMethod,
          payToken,
          price,
          deadLine
        );
        if (isListItem && isListItem.success) {
          const isUpdateListing = await updateListing({
            nftAddress,
            tokenId,
            payMethod,
            payToken,
            price,
            deadLine,
            live: 1,
            tx: isListItem.hash
          });

          if (isUpdateListing) {
            loadData();
            closeModal();
            showSwal("Successfully listed for sale!", "success");
          }
        }
      }
    } catch (err) {
      console.log(err);
      if (err.code === 4001) showSwal(err.message, "warning");
      else showSwal("Error happened while processing.", "warning");
    }
    setButtonLoading(false);
  };

  const closeModal = () => {
    setModalOpen(false);
    setButtonLoading(false);
  };

  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={isModalOpen}
      onRequestClose={() => {
        !buttonLoading && closeModal();
      }}
      contentLabel="Sell Modal"
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "overlay-after",
        beforeClose: "overlay-before"
      }}
      className={{
        base: "content-base",
        afterOpen: "content-after",
        beforeClose: "content-before"
      }}
      closeTimeoutMS={500}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="modal-title2">List for Sale</h2>
        <div className="close" onClick={closeModal}>
          <i className="fa close-icon">&#xf00d;</i>
        </div>
      </div>
      <p className="modal-description text-white">
        {/* You are about to make a lit for #{token_id} */}
      </p>
      <h3 className="modal-big-text">Set a price</h3>
      <div className="position-relative">
        <input
          type="text"
          placeholder="Enter price"
          className="modal-address m-0 p-2"
          onChange={(e) => setSellPrice(e.target.value)}
          value={sellPrice || ""}
          readOnly={buttonLoading}
        />
        <p className="count unit me-2">ETH</p>
      </div>
      <div className="row-item">
        <p className="modal-description m-0">Service fee</p>
        <p className="count">
          {((sellPrice * serviceFee) / 100).toFixed(4)} ETH
        </p>
      </div>
      <div className="row-item">
        <p className="modal-description m-0">Total potential earnings</p>
        <p className="count">
          {((sellPrice * (100 - serviceFee)) / 100).toFixed(4)} ETH
        </p>
      </div>
      <button
        className="modal-ok-btn d-flex align-items-center justify-content-center"
        disabled={sellPrice <= 0 || buttonLoading}
        onClick={() => listing()}
      >
        {buttonLoading ? (
          <ReactLoading
            className="step-loading"
            type="spinningBubbles"
            color="#ffffff"
            height={30}
            width={30}
          />
        ) : (
          "COMPLETE LISTING"
        )}
      </button>
      <button className="modal-cancel-btn" onClick={closeModal}>
        CANCEL
      </button>
    </Modal>
  );
}
