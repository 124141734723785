import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setPersistGrid } from "../../redux/reducers/grid";
import { Filter, List } from "../../components/home";
import "./home.css"
import filter_icon from "../../assets/images/filter-icon.png";
import grid_big from "../../assets/images/grid-big.png";
import grid_small from "../../assets/images/grid-small.png";
import banner from "../../assets/images/banner.jpg";

export const Home = () => {
    const dispatch = useDispatch();
    const gridSelected = useSelector((state) => state.grid.selected);
    const [openFilterModal, setOpenFilterModal] = useState(false);

    return (
        <div className="home">
            <div className="custom-container">
                <div className="d-flex w-100 home-main">
                    <Filter setOpenFilterModal={setOpenFilterModal} openFilterModal={openFilterModal} />
                    <div className="nfts">
                        <img src={banner} alt="marketplace-cover" className="img-fluid" />
                        <div className="text-start mt-5">
                            <h1 className="banner-title">marketplace</h1>
                        </div>
                        <div className="sort">
                            <p className="results d-none d-lg-block">{/* 8,888 results */}</p>
                            <div className="sort-options">
                                <div className="filter-icon d-flex d-lg-none" onClick={() => setOpenFilterModal(true)}>
                                    <img src={filter_icon} alt="filter-icon" />
                                </div>
                                {/* <div className="sort-option position-relative">
                                    <select name="items" className="dropdown-option">
                                        <option>All items</option>
                                    </select>
                                </div>
                                <div className="sort-option position-relative">
                                    <select name="sortby" className="dropdown-option">
                                        <option>Sort by</option>
                                    </select>
                                </div> */}
                                <div className="grid-option float-end">
                                    <img src={grid_big} alt="grid small" className={gridSelected === 0 ? "grid-selected" : ""} onClick={() => dispatch(setPersistGrid(0))} />
                                    <img src={grid_small} alt="grid small" className={gridSelected === 1 ? "grid-selected" : ""} onClick={() => dispatch(setPersistGrid(1))} />
                                </div>
                            </div>
                        </div>
                        {/* <p className="results d-block d-lg-none text-start mb-3">8,888 results</p> */}
                        <List />
                    </div>
                </div>
            </div>
        </div>
    );
}