import { useState } from "react";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import ReactLoading from "react-loading";
import showSwal from "../../utils/showSwal";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import { updatePriceWeb3 } from "../../web3/listing";
import { updateListing } from "../../api";
import useWallet from "../../hooks/useWallet";

export default function ChangePriceModal({
  isModalOpen,
  setModalOpen,
  nftData,
  serviceFee,
  loadData,
}) {
  const { address, isConnected } = useAccount();
  const { handleConnectWallet, userSession } = useWallet();
  const { nftAddress, tokenId } = useParams();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [sellPrice, setSellPrice] = useState(
    ethers.utils.formatEther(nftData?.price)
  );

  const changePrice = async () => {
    setButtonLoading(true);
    try {
      if (
        !isConnected ||
        !userSession ||
        userSession.address.toLowerCase() !== address.toLowerCase()
      ) {
        await handleConnectWallet();
      }

      const payMethod = nftData?.payMethod;
      const payToken = nftData?.payToken;
      const newPrice = ethers.utils.parseEther(sellPrice).toString(10);
      const isUpdatePrice = await updatePriceWeb3(
        nftAddress,
        tokenId,
        payMethod,
        payToken,
        newPrice
      );
      if (isUpdatePrice && isUpdatePrice.success === true) {
        const isUpdateListing = await updateListing({
          nftAddress,
          tokenId,
          payMethod,
          payToken,
          price: newPrice,
          deadLine: nftData?.deadline,
          live: true,
        });

        if (isUpdateListing) {
          loadData();
          closeModal();
          showSwal("The price is successfully changed!", "success");
        }
      }
    } catch (err) {
      console.log(err);
      if (err.code === 4001) showSwal(err.message, "warning");
      else showSwal("Error happened while processing.", "warning");
    }
    setButtonLoading(false);
  };

  const closeModal = () => {
    setModalOpen(false);
    setButtonLoading(false);
  };

  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={isModalOpen}
      onRequestClose={() => {
        !buttonLoading && closeModal();
      }}
      contentLabel="Sell Modal"
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "overlay-after",
        beforeClose: "overlay-before",
      }}
      className={{
        base: "content-base",
        afterOpen: "content-after",
        beforeClose: "content-before",
      }}
      closeTimeoutMS={500}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="modal-title2">Change Price</h2>
        <div className="close" onClick={closeModal}>
          <i className="fa close-icon">&#xf00d;</i>
        </div>
      </div>
      <p className="modal-description text-white">
        {/* You are about to make a lit for #{token_id} */}
      </p>
      <div className="position-relative">
        <input
          type="text"
          placeholder="Enter price"
          className="modal-address m-0 p-2"
          onChange={(e) => setSellPrice(e.target.value)}
          value={sellPrice || ""}
          readOnly={buttonLoading}
        />
        <p className="count unit me-2">ETH</p>
      </div>
      <div className="row-item">
        <p className="modal-description m-0">Service fee</p>
        <p className="count">
          {(sellPrice * (serviceFee / 100)).toFixed(4)} ETH
        </p>
      </div>
      <div className="row-item">
        <p className="modal-description m-0">Total potential earnings</p>
        <p className="count">
          {((sellPrice * (100 - serviceFee)) / 100).toFixed(4)} ETH
        </p>
      </div>
      <button
        className="modal-ok-btn d-flex align-items-center justify-content-center"
        disabled={sellPrice <= 0 || buttonLoading}
        onClick={() => changePrice()}
      >
        {buttonLoading ? (
          <ReactLoading
            className="step-loading"
            type="spinningBubbles"
            color="#ffffff"
            height={30}
            width={30}
          />
        ) : (
          "UPDATE LISTING"
        )}
      </button>
      <button className="modal-cancel-btn" onClick={closeModal}>
        CANCEL
      </button>
    </Modal>
  );
}
