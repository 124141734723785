import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import { signTypedData } from "@wagmi/core";
import { cancelOffer, acceptOffer, gotOffer } from "../../api";
import useTypedData from "../../hooks/useTypedData";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
} from "chart.js";
import { Chart } from "react-chartjs-2";

import { approveNFTWeb3, acceptOfferWeb3 } from "../../web3/offer";
import { AcceptOfferModal, AcceptStepModal } from "../modal";
import showSwal from "../../utils/showSwal";
import localDate from "../../utils/localDate";
import useWallet from "../../hooks/useWallet";

const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export const options = {
  plugins: {
    legend: {
      display: false
    }
  },
  responsive: true,
  transition: {
    duration: 0
  }
};

export const cData = (labels, data) => {
  return {
    labels,
    datasets: [
      {
        type: "line",
        label: "sales",
        data,
        borderColor: "#6b14f8",
        borderWidth: 2,
        width: 10,
        fill: false
      },
      {
        type: "bar",
        label: "sales",
        data,
        backgroundColor: "#1c6cf790",
        borderWidth: 1,
        width: 10
      }
    ]
  };
};

export default function History({
  nftData,
  collectionData,
  priceHistory,
  offers,
  loadData
}) {
  const { nftAddress, tokenId } = useParams();
  const typedData = useTypedData();
  const { address, isConnected } = useAccount();
  const { handleConnectWallet, userSession } = useWallet();

  const [showHistory, setShowHistory] = useState([1, 0, 0]);
  const [acceptInfo, setAcceptInfo] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [acceptModalIsOpen, setAcceptModalIsOpen] = useState(false);
  const [stepModalIsOpen, setStepModalIsOpen] = useState(false);
  const [acceptDetail, setAcceptDetail] = useState();

  const [chartData, setChartData] = useState(cData([], []));

  useEffect(() => {
    let labels = [];
    let datas = [];
    if (!!priceHistory) {
      priceHistory.forEach((e) => {
        var date = localDate(e.updatedAt);
        labels.push(date);
        datas.push(ethers.utils.formatEther(e.price));
      });
      setChartData(cData(labels, datas));
    }
  }, [priceHistory]);

  const removeOffer = async (id, price) => {
    if (
      !isConnected ||
      !userSession ||
      userSession?.address?.toLowerCase() !== address?.toLowerCase()
    ) {
      await handleConnectWallet();
    }
    const signature = await signTypedData(
      typedData(nftAddress, tokenId, tokenAddress, price)
    );
    const isCancelOffer = await cancelOffer({ offerId: id, signature });
    if (isCancelOffer) {
      await loadData();
      showSwal("Successfully removed!", "success");
    }
    setButtonLoading(false);
  };

  const handleAccept = async (offer) => {
    if (!isConnected || !userSession) {
      await handleConnectWallet();
    }
    setAcceptInfo(offer);
    setAcceptModalIsOpen(true);
  };

  const approveOffer = async (offer) => {
    setButtonLoading(true);
    if (
      !isConnected ||
      !userSession ||
      userSession.address.toLowerCase() !== address.toLowerCase()
    ) {
      await handleConnectWallet();
    }
    const acceptedOffer = await acceptOffer({ offerId: offer.id, address });
    if (acceptedOffer) {
      setAcceptModalIsOpen(false);
      setAcceptDetail(acceptedOffer);
      setStepModalIsOpen(true);
    }
    setButtonLoading(false);
  };

  const startTransaction = async () => {
    if (acceptDetail) {
      setIsLoading(true);
      try {
        if (
          !isConnected ||
          !userSession ||
          userSession.address.toLowerCase() !== address.toLowerCase()
        ) {
          await handleConnectWallet();
        }
        const isApproveNFT = await approveNFTWeb3(
          address,
          acceptDetail.nftAddress
        );
        if (isApproveNFT) {
          const isAcceptOffer = await acceptOfferWeb3(
            address,
            acceptDetail.nftAddress,
            acceptDetail.tokenId,
            acceptDetail.payToken,
            acceptDetail.price,
            acceptDetail.offerer,
            acceptDetail.signature
          );

          if (isAcceptOffer && isAcceptOffer.success === true) {
            const isGotOffered = await gotOffer({
              nftAddress,
              tokenId,
              transaction: isAcceptOffer.hash,
              signature: acceptDetail.signature
            });
            if (isGotOffered) {
              closeModal();
              await loadData();
            }
          }
        }
      } catch (err) {
        console.log(err);
        if (err.code === 4001) showSwal(err.message, "warning");
        else showSwal("Error happened while processing.", "warning");
      }
      setIsLoading(false);
    }
  };

  const handleShowHistory = (index) => {
    const nextCounters = showHistory.map((c, i) => {
      if (i === index - 1) {
        if (c) {
          c = 0;
        } else {
          c = 1;
        }
        return c;
      } else {
        return c;
      }
    });
    setShowHistory(nextCounters);
  };

  const closeModal = () => {
    setAcceptModalIsOpen(false);
    setStepModalIsOpen(false);
  };

  return (
    <>
      <div className="row-info">
        <div
          onClick={() => handleShowHistory(1)}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="d-flex align-items-center gap-3">
            <i className="fas fa-chart-line"></i>
            <p className="row-info-text">Price History</p>
          </div>
          <i className="fa-solid fa-angle-down"></i>
        </div>
        <div
          className={
            "details-content text-center filter-values " +
            (showHistory[0] ? "show-filter" : "hide-filter")
          }
        >
          {priceHistory.length > 0 ? (
            <Chart options={options} data={chartData} />
          ) : (
            <>
              <p>No events have occurred yet</p>
              <p>Check back later.</p>
            </>
          )}
        </div>
      </div>
      {/* <div className="row-info">
    <div onClick={() => handleShowHistory(2)} className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-3">
            <i className="fas fa-sign"></i>
            <p className="row-info-text">Listings</p>
        </div>
        <i className="fa-solid fa-angle-down"></i>
    </div>
    <div className={"details-content text-center filter-values " + (showHistory[1] ? "show-filter" : "hide-filter")}>
        <p>No events have occurred yet</p><p>Check back later.</p>
    </div>
</div> */}
      <div className="row-info">
        <div
          onClick={() => handleShowHistory(3)}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="d-flex align-items-center gap-3">
            <i className="fas fa-dollar-sign" style={{ marginLeft: "7px" }}></i>
            <p className="row-info-text">Offers</p>
          </div>
          <i className="fa-solid fa-angle-down"></i>
        </div>
        <div
          className={
            "details-content text-center filter-values " +
            (showHistory[2] ? "show-filter" : "hide-filter")
          }
        >
          {offers?.length > 0 ? (
            <div className="activity-table">
              <table>
                <tbody>
                  <tr>
                    <th>Price</th>
                    <th>From</th>
                    <th></th>
                  </tr>
                  {offers?.map((offer, index) => (
                    <tr key={index}>
                      <td>{ethers.utils.formatEther(offer.price)}</td>
                      {/* <td>{offer.offerer.substring(2, 8).toUpperCase()}</td> */}
                      <td>{offer.offerer}</td>
                      <td>
                        {offer.offerer.toLowerCase() ===
                          address?.toLowerCase() && (
                          <button
                            className="cancel-btn"
                            onClick={() => removeOffer(offer.id, offer.price)}
                          >
                            cancel
                          </button>
                        )}
                        {nftData.owner_of?.toLowerCase() ===
                          address?.toLowerCase() && (
                          <button
                            className="cancel-btn"
                            onClick={() => handleAccept(offer)}
                          >
                            accept
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No offers yet</p>
          )}
        </div>
      </div>
      {!!acceptInfo && (
        <AcceptOfferModal
          isModalOpen={acceptModalIsOpen && isConnected && userSession}
          closeModal={closeModal}
          acceptInfo={acceptInfo}
          serviceFee={collectionData?.creatorFee}
          approveOffer={approveOffer}
          buttonLoading={buttonLoading}
        />
      )}
      <AcceptStepModal
        isModalOpen={stepModalIsOpen && isConnected && !!userSession}
        startTransaction={startTransaction}
        isLoading={isLoading}
        closeModal={closeModal}
      />
    </>
  );
}
