import Swal from "sweetalert2";

const showSwal = (text, icon) => {
  Swal.fire({
    position: "bottom-end",
    icon: icon,
    html: `<h5>${text}<h5>`,
    showConfirmButton: false,
    timer: icon === "warning" ? 3000 : 1500,
    width: 350,
    padding: "0em",
  });
};

export default showSwal;
