import { useState } from "react";
import ReactLoading from "react-loading";
import Modal from "react-modal";
import { useAccount } from "wagmi";
import useWallet from "../../hooks/useWallet";
import { transferNFTWeb3 } from "../../web3/nft";
import showSwal from "../../utils/showSwal";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { burnNFT } from "../../api";

export default function BurnModal({ isModalOpen, setBurnModalIsOpen }) {
  const navigate = useNavigate();
  const { nftAddress, tokenId } = useParams();
  const [buttonLoading, setButtonLoading] = useState(false);
  const { address, isConnected } = useAccount();
  const { handleConnectWallet, userSession } = useWallet();

  const closeModal = () => {
    setBurnModalIsOpen(false);
    setButtonLoading(false);
  };

  const handleBurn = async () => {
    setButtonLoading(true);
    try {
      if (
        !isConnected ||
        !userSession ||
        userSession.address.toLowerCase() !== address.toLowerCase()
      ) {
        await handleConnectWallet();
      }
      const receiverAddress = "0x0000000000000000000000000000000000000000";

      const isBurned = await transferNFTWeb3(
        address,
        nftAddress,
        receiverAddress,
        tokenId
      );
      if (isBurned && isBurned.success === true) {
        const isBurnedFromDB = await burnNFT({ nftAddress, tokenId });
        if (isBurnedFromDB) {
          navigate("/");
        }
      }
    } catch (err) {
      console.log(err);
      if (err.code === 4001) showSwal(err.message, "warning");
      else showSwal("Error happened while processing.", "warning");
    }
    setButtonLoading(false);
  };
  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={isModalOpen}
      onRequestClose={() => {
        !buttonLoading && closeModal();
      }}
      contentLabel="Burn Token Modal"
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "overlay-after",
        beforeClose: "overlay-before",
      }}
      className={{
        base: "content-base",
        afterOpen: "content-after",
        beforeClose: "content-before",
      }}
      closeTimeoutMS={500}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="modal-title2">Burn token</h2>
        <div className="close" onClick={closeModal}>
          <i className="fa close-icon">&#xf00d;</i>
        </div>
      </div>
      <p className="modal-description">
        Are you sure to burn this token? This action cannot be undone. Token
        will be transfered to zero address.
      </p>
      <button
        className="modal-ok-btn d-flex align-items-center justify-content-center"
        disabled={buttonLoading}
        onClick={() => handleBurn()}
      >
        {buttonLoading ? (
          <ReactLoading
            className="step-loading"
            type="spinningBubbles"
            color="#ffffff"
            height={30}
            width={30}
          />
        ) : (
          "REMOVE NOW"
        )}
      </button>
      <button className="modal-cancel-btn" onClick={closeModal}>
        CANCEL
      </button>
    </Modal>
  );
}
