import { useEffect, useState } from "react";
import filter_icon from "../../assets/images/filter-icon.png";
import { useSearchParams } from "react-router-dom";
import { fetchCollections } from "../../api";

export default function Filter({ openFilterModal, setOpenFilterModal }) {
  const [showFilter, setShowFilter] = useState([0, 0, 1, 0]);
  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");
  const [rarityFrom, setRarityFrom] = useState("");
  const [rarityTo, setRarityTo] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const [collections, setCollections] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const asyncFn = async () => {
      const collections = await fetchCollections();
      setCollections(collections);
    };
    /* if(searchParams.get("priceFrom")) handleStatusFilter(2);
    if(searchParams.get("rarityFrom")) handleStatusFilter(4); */
    setPriceFrom(searchParams.get("priceFrom") || "");
    setPriceTo(searchParams.get("priceTo") || "");
    setRarityFrom(searchParams.get("rarityFrom") || "");
    setRarityTo(searchParams.get("rarityTo") || "");
    asyncFn();
  }, [searchParams]);

  const handleSearch = (key, value) => {
    if (key === "status") {
      if (value === "ALL") {
        searchParams.delete("status");
      } else {
        if (searchParams.has("status")) searchParams.set("status", value);
        else searchParams.append("status", value);
      }
      searchParams.delete("priceFrom");
      searchParams.delete("priceTo");
      searchParams.delete("rarityFrom");
      searchParams.delete("rarityTo");
      setPriceFrom("");
      setPriceTo("");
    } else if (key === "price") {
      searchParams.delete("status");
      searchParams.delete("rarityFrom");
      searchParams.delete("rarityTo");
      if (searchParams.has("priceFrom")) {
        searchParams.set("priceFrom", priceFrom);
        searchParams.set("priceTo", priceTo);
      } else {
        searchParams.append("priceFrom", priceFrom);
        searchParams.append("priceTo", priceTo);
      }
      setSearchParams(searchParams);
    } else if (key === "rarity") {
      searchParams.delete("status");
      searchParams.delete("priceFrom");
      searchParams.delete("priceTo");
      if (searchParams.has("rarityFrom")) {
        searchParams.set("rarityFrom", rarityFrom);
        searchParams.set("rarityTo", rarityTo);
      } else {
        searchParams.append("rarityFrom", rarityFrom);
        searchParams.append("rarityTo", rarityTo);
      }
      setSearchParams(searchParams);
    } else if (key === "collection") {
      if (searchParams.has("collection")) searchParams.set("collection", value);
      else searchParams.append("collection", value);
    }
    setSearchParams(searchParams);
  };

  const handleStatusFilter = (index) => {
    const nextCounters = showFilter.map((c, i) => {
      if (i === index - 1) {
        if (c) {
          c = 0;
        } else {
          c = 1;
        }
        return c;
      } else {
        return c;
      }
    });
    setShowFilter(nextCounters);
  };

  return (
    <div
      className={"filter " + (openFilterModal ? "showFilterModal" : "nonshow")}
    >
      <div className={scrollPosition > 150 ? "sticky": ""}>
        <div className="filter-row">
          <div className="filter-option">
            <h3 className="filter-title">Filter</h3>
            <img
              src={filter_icon}
              alt="filter-icon"
              className="d-none d-lg-block"
            />
            <i
              className="fa close-icon d-block d-lg-none"
              onClick={() => setOpenFilterModal(false)}
            >
              &#xf00d;
            </i>
          </div>
        </div>
        <div className="filter-row">
          <div className="filter-option" onClick={() => handleStatusFilter(1)}>
            <h3 className="filter-option-title">Status</h3>
            <i className="fa-solid fa-angle-down"></i>
          </div>
          <div
            className={
              "filter-values " +
              (showFilter[0] ? "show-filter" : "hide-filter") +
              " status"
            }
          >
            <button
              className={
                "filter-btn " +
                (!searchParams.has("status") && !searchParams.has("rarityFrom")
                  ? "active"
                  : "")
              }
              onClick={() => handleSearch("status", "ALL")}
            >
              All
            </button>
            <button
              className={
                "filter-btn " +
                (searchParams.get("status") === "HAS_OFFER" ? "active" : "")
              }
              onClick={() => handleSearch("status", "HAS_OFFER")}
            >
              Has Offers
            </button>
            <button
              className={
                "filter-btn " +
                (searchParams.get("status") === "BUY_NOW" ? "active" : "")
              }
              onClick={() => handleSearch("status", "BUY_NOW")}
            >
              Buy Now
            </button>
            {/* <button
            className={
              "filter-btn " + (statusFilterOption === 1 ? "active" : "")
            }
            onClick={() => setStatusFilterOption(1)}
          >
            On Auction
          </button> */}
          </div>
        </div>
        <div className="filter-row">
          <div className="filter-option" onClick={() => handleStatusFilter(2)}>
            <h3 className="filter-option-title">Price</h3>
            <i className="fa-solid fa-angle-down"></i>
          </div>
          <div
            className={
              "filter-values flex-column " +
              (showFilter[1] ? "show-filter" : "hide-filter") +
              " price"
            }
          >
            <div className="d-flex align-items-center gap-1">
              <div className="sort-option position-relative">
                <select name="items" className="dropdown-option">
                  <option>ETH</option>
                </select>
              </div>
              <input
                value={priceFrom}
                onChange={(e) => setPriceFrom(e.target.value)}
                type="number"
                placeholder="Min"
                className="min-price"
              />
              <p className="to">to</p>
              <input
                value={priceTo}
                onChange={(e) => setPriceTo(e.target.value)}
                type="number"
                placeholder="max"
                className="min-price"
              />
            </div>
            <div>
              {priceFrom && priceTo && priceFrom >= priceTo && (
                <p className="text-danger">
                  Minimum must be less than maximum.
                </p>
              )}
              <button
                className="filter-btn active"
                disabled={!priceFrom || !priceTo || priceFrom >= priceTo}
                onClick={() => handleSearch("price")}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
        <div className="filter-row">
          <div className="filter-option" onClick={() => handleStatusFilter(3)}>
            <h3 className="filter-option-title">Collections</h3>
            <i className="fa-solid fa-angle-down"></i>
          </div>
          <div
            className={
              "filter-values " +
              (showFilter[2] ? "show-filter" : "hide-filter") +
              " categories"
            }
          >
            {collections?.map((collection, index) => (
              <div className="category" key={index}>
                <input
                  type="checkbox"
                  className="checkbox-input"
                  value={collection.shortName}
                  id={collection.shortName}
                  checked={
                    searchParams.get("collection") === collection.shortName ||
                    (!searchParams.has("collection") && index === 0)
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleSearch("collection", e.target.value);
                  }}
                />
                <label htmlFor={collection.shortName}>
                  <span className="checkbox"></span>
                </label>
                <label htmlFor={collection.shortName}>{collection.name}</label>
              </div>
            ))}
          </div>
        </div>
        <div className="filter-row">
          <div className="filter-option" onClick={() => handleStatusFilter(4)}>
            <h3 className="filter-option-title">Rank #</h3>
            <i className="fa-solid fa-angle-down"></i>
          </div>
          <div
            className={
              "filter-values " +
              (showFilter[3] ? "show-filter" : "hide-filter") +
              " rank"
            }
          >
            <div className="d-flex align-items-center gap-1">
              <input
                value={rarityFrom}
                onChange={(e) => setRarityFrom(e.target.value)}
                type="number"
                placeholder="1"
                className="min-price text-center"
              />
              <p className="to">to</p>
              <input
                value={rarityTo}
                onChange={(e) => setRarityTo(e.target.value)}
                type="number"
                placeholder="8888"
                className="min-price text-center"
              />
              <button
                className="filter-btn active"
                disabled={!rarityFrom || !rarityTo || rarityFrom >= rarityTo}
                onClick={() => handleSearch("rarity")}
              >
                Apply
              </button>
            </div>
            <div>
              {rarityFrom && rarityTo && rarityFrom >= rarityTo && (
                <p className="text-danger">
                  Minimum must be less than maximum.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
