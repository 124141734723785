import Modal from "react-modal";

export default function ReportModal({ reportModalIsOpen, closeModal }) {
  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={reportModalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Report Modal"
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "overlay-after",
        beforeClose: "overlay-before",
      }}
      className={{
        base: "content-base",
        afterOpen: "content-after",
        beforeClose: "content-before",
      }}
      closeTimeoutMS={500}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="modal-title2">Report</h2>
        <div className="close" onClick={closeModal}>
          <i className="fa close-icon">&#xf00d;</i>
        </div>
      </div>
      <p className="modal-description">
        Describe why you think this item should be removed from marketplace
      </p>
      <label>MESSAGE</label>
      <textarea placeholder="Tell us the details" rows="3"></textarea>
      <button className="modal-ok-btn">REMOVE NOW</button>
      <button className="modal-cancel-btn" onClick={closeModal}>
        CANCEL
      </button>
    </Modal>
  );
}
