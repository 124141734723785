import { ethers } from "ethers";
import localDate from "../../utils/localDate";
import getAdrName from "../../utils/getAdrName";
import { useState } from "react";

export default function Activity({ data, collectionData }) {
  const [showActivity, setShowActivity] = useState(true);

  return (
    <>
      <div className="mt-5">
        <div className="row-info">
          <div
            className="d-flex align-items-center justify-content-between gap-3"
            onClick={() => {
              setShowActivity(!showActivity);
            }}
          >
            <p className="row-info-text">Item Activity</p>
            <i className="fa-solid fa-angle-down"></i>
          </div>
        </div>
      </div>
      <div
        className={
          "activity-table filter-values " +
          (showActivity ? "show-filter" : "hide-filter")
        }
      >
        <table>
          <tbody>
            <tr>
              <th>Action</th>
              <th>Price</th>
              <th>From</th>
              <th>To</th>
              <th>Date</th>
            </tr>
            {data?.map((e, i) => (
              <tr key={i}>
                <td>
                  {!!e.from_address &&
                    (e.from_address ===
                    "0x0000000000000000000000000000000000000000" ? (
                      <>
                        <i className="fa-solid fa-cube"></i> Minted
                      </>
                    ) : (
                      <>
                        <i className="fa-solid fa-exchange-alt"></i> Transfer
                      </>
                    ))}
                  {!!e.creatorAddress && (
                    <>
                      <i className="fa-solid fa-cart-shopping"></i> Sale
                    </>
                  )}
                  {!!e.offerer && (
                    <>
                      <i className="fa-solid fa-dollar-sign"></i> Offer Accepted
                    </>
                  )}
                </td>
                <td>
                  {!!e.price &&
                    e.price > 0 &&
                    ethers.utils.formatEther(e.price) + " ETH"}
                </td>
                <td>
                  {!!e.from_address &&
                    (e.from_address ===
                    "0x0000000000000000000000000000000000000000"
                      ? "NullAddress"
                      : getAdrName(e.from_address))}
                  {getAdrName(e.seller) || getAdrName(e.creatorAddress)}
                </td>
                <td>
                  {!!e.to_address && getAdrName(e.to_address)}
                  {getAdrName(e.offerer) || getAdrName(e.buyerAddress)}
                </td>
                <td>
                  {localDate(e.block_timestamp)}{" "}
                  {!!e.transaction_hash && (
                    <a
                      href={
                        collectionData?.scanLink + "tx/" + e.transaction_hash
                      }
                      rel="noreferrer"
                      target="_blank"
                      className="text-white"
                    >
                      <i className="fa-solid fa-external-link-alt"></i>
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
