import { useCallback, useEffect } from "react";
import React, { createContext, useState } from "react";
import {
  useAccount,
  useNetwork,
  useConnect,
  useDisconnect,
  useSignMessage
} from "wagmi";
import { fetchBalance, watchNetwork } from "@wagmi/core";
import mbhAxios from "../api/api";
import { fetchUserInfo } from "../api";
import Modal from "react-modal";

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const { address, isConnected, connector: activeConnector } = useAccount();
  const { chain } = useNetwork();
  const { connectAsync, connectors, error, isLoading, pendingConnector } =
    useConnect();
  const { disconnectAsync } = useDisconnect();
  const { signMessageAsync } = useSignMessage();

  const [walletModalIsOpen, setWalletModalIsOpen] = useState(false);
  const [networkId, setNetworkId] = useState(+process.env.REACT_APP_CHAIN_ID);
  const [userSession, setUserSession] = useState();
  const [userInfo, setUserInfo] = useState();
  const [balance, setBalance] = useState(0);

  const checkAuthenticate = (wallet) => {
    mbhAxios
      .get("authenticate", {
        withCredentials: true
      })
      .then(({ data }) => {
        const { iat, ...authData } = data; // remove unimportant iat value
        if (authData.address?.toLowerCase() === wallet.toLowerCase()) {
          setUserSession(authData);
        } else {
          setUserSession();
        }
      })
      .catch((err) => {
        setUserSession();
      });
  };

  const handleConnectWallet = async () => {
    if (isConnected && activeConnector)
      await connectWallet({ connector: activeConnector });
    else {
      setWalletModalIsOpen(true);
    }
  };

  const connectWallet = async ({ connector }) => {
    let wallet = "";
    let chainId = "";
    if (!isConnected) {
      const { account, chain } = await connectAsync({
        chainId: +process.env.REACT_APP_CHAIN_ID,
        connector
      });
      wallet = account;
      chainId = chain.id;
    } else {
      wallet = address;
      chainId = chain.id;
    }

    if (
      !userSession ||
      userSession.address.toLowerCase() !== wallet.toLowerCase()
    ) {
      const userData = { address: wallet, chain: chainId, network: "evm" };
      const { data } = await mbhAxios.post("requestMessage", userData);
      const message = data.message;
      // signing the received message via metamask
      const signature = await signMessageAsync({ message });
      await mbhAxios.post(
        "verifyMessage",
        { message, signature },
        { withCredentials: true }
      );
      checkAuthenticate(wallet);
    }
  };

  const disconnectWallet = async () => {
    if (isConnected) {
      await disconnectAsync();
      await mbhAxios.get("logout", { withCredentials: true });
      setUserSession();
    }
  };

  useEffect(() => {
    watchNetwork((network) => {
      if (network && network.chain) setNetworkId(network.chain.id);
      else setNetworkId(0);
    });
  });

  const fetchWalletBalance = useCallback(async () => {
    if (isConnected) {
      const balance = await fetchBalance({
        address,
        chainId: +process.env.REACT_APP_CHAIN_ID
      });
      setBalance((+balance.formatted).toFixed(4));
    }
  }, [address, isConnected]);

  useEffect(() => {
    fetchWalletBalance();
  }, [address, isConnected, networkId]);

  useEffect(() => {
    if (address && isConnected) {
      checkAuthenticate(address);
      fetchUserInfo({ address }).then((data) => {
        setUserInfo(data);
      });
      setWalletModalIsOpen(false);
    } else {
      setUserSession();
      setUserInfo();
    }
  }, [address, isConnected]);

  return (
    <WalletContext.Provider
      value={{
        setWalletModalIsOpen,
        handleConnectWallet,
        disconnectWallet,
        fetchWalletBalance,
        balance,
        userSession,
        userInfo
      }}
    >
      {networkId !== +process.env.REACT_APP_CHAIN_ID && isConnected && (
        <div className="network-err-msg">
          <h3>Please switch to main network</h3>
        </div>
      )}
      {children}
      <Modal
        appElement={document.getElementById("root")}
        isOpen={walletModalIsOpen}
        onRequestClose={() => setWalletModalIsOpen(false)}
        contentLabel="Wallet Modal"
        overlayClassName={{
          base: "overlay-base",
          afterOpen: "overlay-after",
          beforeClose: "overlay-before"
        }}
        className={{
          base: "content-base",
          afterOpen: "content-after",
          beforeClose: "content-before"
        }}
        closeTimeoutMS={500}
      >
        <div className="text-center">
          <h4 className="text-white">CONNECT YOUR WALLET</h4>
          <div className="d-flex align-items-center flex-column">
            {connectors.length > 0 &&
              connectors?.map(
                (connector) =>
                  connector.ready && (
                    <a
                      disabled={!connector.ready}
                      key={connector.id}
                      onClick={() => connectWallet({ connector })}
                      className="wallet-btn"
                    >
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/wallet/" +
                          connector.id +
                          ".png"
                        }
                      />
                      {connector.name}
                      {!connector.ready && " (unsupported)"}
                      {isLoading &&
                        connector.id === pendingConnector?.id &&
                        " (connecting)"}
                    </a>
                  )
              )}
          </div>

          {error && <div className="text-warning mt-3">{error.message}</div>}
        </div>
      </Modal>
    </WalletContext.Provider>
  );
};
