import { useState } from "react";
import { Link } from "react-router-dom";
import { useAccount, useConnect } from "wagmi";
import useWallet from "../hooks/useWallet";
import logo from "../assets/images/logo.png";
import avatar from "../assets/images/avatar.png";
import disconnect_img from "../assets/images/disconnect.png";
/* import profile from "../assets/images/profile.png"; */
import items from "../assets/images/items.png";
import copy_icon from "../assets/images/copy-icon.png";
import ethereum from "../assets/images/ethereum.png";
import useCopyClip from "../hooks/useCopyClip";

export const Navbar = () => {
  const {
    handleConnectWallet,
    disconnectWallet,
    balance,
    userInfo
  } = useWallet();
  const { address: account, isConnected } = useAccount();
  const { copyToClipboard, copyStatus } = useCopyClip();

  return (
    <>
      <div className="top-nav">
        <nav className="custom-container navbar navbar-expand-lg w-100">
          <div className="d-flex justify-content-center justify-content-md-between w-100 gap-3 gap-lg-4">
            <div className="d-flex my-2 my-sm-0 gap-lg-5 gap-3 w-65">
              <Link
                className="navbar-brand me-2 mb-1 d-flex justify-content-start justify-content-md-center align-items-center"
                to="/#"
              >
                <img src={logo} alt="" loading="lazy" />
              </Link>

              {/* <form className="input-group my-auto search-box d-none d-md-flex">
                <input
                  autoComplete="off"
                  type="search"
                  className="form-control rounded"
                  placeholder="Search"
                  style={{ minWidth: "125px" }}
                />
                <span className="text-white input-group-text border-0 d-flex">
                  <i className="fas fa-search"></i>
                </span>
              </form> */}
            </div>

            <ul className="navbar-nav flex-row align-items-center gap-lg-4 gap-3">
              {/* <li className="nav-item d-md-none d-block">
                <a className="nav-link" href="#">
                  <span>
                    <i className="fas fa-search text-white"></i>
                  </span>
                </a>
              </li> */}
              {!!account && (
                <li className="nav-item">
                  <Link className="nav-link" to="/profile">
                    <span>
                      <i className="fa-solid fa-circle-user text-white"></i>
                    </span>
                  </Link>
                </li>
              )}

              {/*<li className="nav-item">
                <a className="nav-link" href="#">
                  <span>
                    <i className="fas fa-shopping-cart text-white"></i>
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link hidden-arrow" href="#">
                  <i className="fas fa-bell text-white"></i>
                </a>
              </li> */}
              <li className="nav-item">
                {/* ===========connect wallet button========== */}
                {isConnected ? (
                  <div className="d-flex gap-lg-4 gap-2">
                    {/* <a
                    className="nav-link d-flex align-items-center upload-btn connect-btn"
                    href="#"
                  >
                    <nobr>UPLOAD</nobr>
                  </a> */}
                    <div className="dropdown">
                      <div
                        className="balance"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={avatar}
                          alt="avatar"
                          className="avatar-icon"
                        />
                        <p className="m-0">
                          <nobr>{balance} ETH</nobr>
                        </p>
                      </div>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <h4 className="dropdown-id">
                          {!!account &&
                            (userInfo?.name ||
                              account.substring(2, 8).toUpperCase())}
                        </h4>
                        {!!account && (
                          <button
                            className="border-0 bg-transparent"
                            onClick={() => copyToClipboard(account)}
                          >
                            <h5 className="dropdown-wallet-address">
                              {account.substring(0, 15)}...
                              {account.substring(account.length - 4)}
                              <img src={copy_icon} alt="copy icon" />
                            </h5>
                          </button>
                        )}
                        <div className="dropdown-balance">
                          <img
                            src={ethereum}
                            alt="ethereum"
                            className="ethereum"
                          />
                          <div>
                            <div className="dropdown-wallet-address">
                              Balance
                            </div>
                            <h3 className="wallet-balance">{balance} ETH</h3>
                          </div>
                        </div>
                        {/* <li>
                          <Link className="dropdown-item" to="/profile">
                            <img src={profile} alt="dollar" /> My profile
                          </Link>
                        </li> */}
                        <li>
                          <Link className="dropdown-item" to="/profile">
                            <img src={items} alt="dollar" /> My items
                          </Link>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={() => disconnectWallet()}
                          >
                            <img src={disconnect_img} alt="dollar" /> Disconnect
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <button
                    className="nav-link d-flex align-items-center connect-btn"
                    /* onClick={() => setWalletModalIsOpen(true)} */
                    onClick={() => handleConnectWallet()}
                  >
                    <nobr>CONNECT WALLET</nobr>
                  </button>
                )}
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};
