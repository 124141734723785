import { useParams } from "react-router-dom";
import Modal from "react-modal";
import ReactLoading from "react-loading";
import { useState, useEffect } from "react";
import useTypedData from "../../hooks/useTypedData";
import { useAccount } from "wagmi";
import { signTypedData } from "@wagmi/core";
import { approveTokenWeb3 } from "../../web3/offer";
import { createOffer } from "../../api/offer";
import { fetchBalance } from "@wagmi/core";
import { ethers } from "ethers";
import showSwal from "../../utils/showSwal";
import useWallet from "../../hooks/useWallet";

const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;

export default function MakeOfferModal(props) {
  const { isModalOpen, loadData, closeModal } = props;
  const { isConnected, address } = useAccount();
  const { handleConnectWallet, userSession, userInfo } = useWallet();
  const { nftAddress, tokenId } = useParams();
  const typedData = useTypedData();
  const [wethBalance, setWethBalance] = useState(0);
  const [bidAmount, setBidAmount] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);

  const getWethBalance = () => {
    setBalanceLoading(true);
    fetchBalance({
      address,
      token: tokenAddress,
      chainId: +process.env.REACT_APP_CHAIN_ID
    }).then((weth) => {
      setWethBalance(weth.formatted);
      setBalanceLoading(false);
    });
  };

  useEffect(() => {
    getWethBalance();
    setBidAmount(0);
  }, [address]);

  const placeBid = async () => {
    setButtonLoading(true);
    if (
      !isConnected ||
      !userSession ||
      userSession.address.toLowerCase() !== address.toLowerCase()
    ) {
      await handleConnectWallet();
    }
    try {
      const isApprovedToken = await approveTokenWeb3(
        address,
        tokenAddress,
        bidAmount
      );
      if (isApprovedToken) {
        const signature = await signTypedData(
          typedData(
            nftAddress,
            tokenId,
            tokenAddress,
            ethers.utils.parseEther(bidAmount).toString(10)
          )
        );

        const isCreateOffer = await createOffer({
          nftAddress,
          tokenId,
          payToken: tokenAddress,
          price: ethers.utils.parseEther(bidAmount).toString(10),
          signature
        });
        closeModal();
        if (isCreateOffer) {
          showSwal("Your offer has been submitted!", "success");
          await loadData();
        }
      }
    } catch (err) {
      if (err.code === 4001) showSwal(err.message, "warning");
      else showSwal("Error happened while processing.", "warning");
      console.log(err);
    }
    setButtonLoading(false);
  };

  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={isModalOpen && isConnected && !!userSession}
      onRequestClose={() => {
        !buttonLoading && closeModal();
      }}
      contentLabel="Offer Modal"
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "overlay-after",
        beforeClose: "overlay-before"
      }}
      className={{
        base: "content-base",
        afterOpen: "content-after",
        beforeClose: "content-before"
      }}
      closeTimeoutMS={500}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="modal-title2">Make an Offer</h2>
        <div className="close" onClick={closeModal}>
          <i className="fa close-icon">&#xf00d;</i>
        </div>
      </div>
      <p className="modal-description text-white">
        You are about to make an offer for #{tokenId} from{" "}
        {!!address && (userInfo?.name || address.substring(2, 8).toUpperCase())}
      </p>
      <h3 className="modal-big-text">Your bid</h3>
      <div
        className={
          bidAmount > wethBalance
            ? "warning position-relative"
            : "position-relative"
        }
      >
        <input
          type="text"
          placeholder="Enter bid"
          className="modal-address m-0 p-2"
          onChange={(e) => setBidAmount(e.target.value)}
          value={bidAmount || ""}
        />
        <p className="count unit me-2">WETH</p>
      </div>
      {bidAmount > wethBalance && (
        <p className="red">
          <i className="fa fa-exclamation-triangle"></i>You don't have enough
          WETH
        </p>
      )}

      <div className="row-item">
        <p className="modal-description m-0">
          Your balance{" "}
          <i
            className={
              "fa-solid fa-arrows-rotate refresh-icon " +
              (balanceLoading && "fa-spin")
            }
            onClick={() => getWethBalance()}
          />
        </p>
        <p className="count">{(+wethBalance).toFixed(4)} WETH </p>
      </div>
      <div className="row-item float-end">
        <a
          href="https://app.uniswap.org/#/tokens/ethereum/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
          target="_blank"
          className="text-white count"
          rel="noreferrer"
        >
          GET WETH
        </a>
      </div>
      {/* <div className="row-item">
        <p className="modal-description m-0">Service fee</p>
        <p className="count">0 ETH</p>
      </div>
      <div className="row-item">
        <p className="modal-description m-0">Total bid amount</p>
        <p className="count">{bidAmount} WETH</p>
      </div> */}
      <button
        className="modal-ok-btn d-flex align-items-center justify-content-center"
        disabled={bidAmount > wethBalance || bidAmount <= 0 || buttonLoading}
        onClick={() => placeBid()}
      >
        {buttonLoading ? (
          <ReactLoading
            className="step-loading"
            type="spinningBubbles"
            color="#ffffff"
            height={30}
            width={30}
          />
        ) : (
          "PLACE A BID"
        )}
      </button>
      <button className="modal-cancel-btn" onClick={closeModal}>
        CANCEL
      </button>
    </Modal>
  );
}
