import { createSlice } from "@reduxjs/toolkit";

const initialGridSlice = {
  selected: 0,
};

export const gridSlice = createSlice({
  name: "grid",
  initialState: initialGridSlice,
  reducers: {
    setPersistGrid: (state, action) => {
      state.selected = action.payload;
    },
  },
});

export const { setPersistGrid } = gridSlice.actions;

export default gridSlice.reducer;
