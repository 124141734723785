import mbhAxios from "./api";
import showSwal from "../utils/showSwal";

export const fetchUserInfo = async ({ address }) => {
  try {
    const response = await mbhAxios.post("getUser", {
      address,
    });
    if (response.data && response.data.success === true) {
      return response.data.user;
    }
    return;
  } catch (err) {
    showSwal(err.message, "error");
    return;
  }
};

export const updateUser = async (params) => {
  try {
    const response = await mbhAxios.post("updateUser", params);
    if (response.data && response.data.success === true) {
      return response.data.success;
    }
    return;
  } catch (err) {
    showSwal(err.message, "error");
    return;
  }
};
