import { useState } from "react";
import { useAccount } from "wagmi";
import button_icon from "../../assets/images/mbh-icon.png";
import useWallet from "../../hooks/useWallet";
import { SellModal, RemoveSaleModal } from "../modal";

export default function Seller({ nftData, loadData, collectionData }) {
  const { isConnected, address } = useAccount();
  const { handleConnectWallet, userSession } = useWallet();
  const [sellModalIsOpen, setSellModalIsOpen] = useState(false);
  const [removeSaleModalIsOpen, setRemoveSaleModalIsOpen] = useState(false);

  const handleSell = async () => {
    if (
      !isConnected ||
      !userSession ||
      userSession.address.toLowerCase() !== address.toLowerCase()
    ) {
      await handleConnectWallet();
    }
    setSellModalIsOpen(true);
  };

  const handelRemoveSell = async () => {
    if (
      !isConnected ||
      !userSession ||
      userSession.address.toLowerCase() !== address.toLowerCase()
    ) {
      await handleConnectWallet();
    }
    setRemoveSaleModalIsOpen(true);
  };

  return (
    <>
      <div className="button-group mt-4">
        {!nftData.price ? (
          <button className="buy-btn" onClick={() => handleSell()}>
            <img src={button_icon} alt="button-icon" />
            SELL
          </button>
        ) : (
          <button className="offer-btn" onClick={() => handelRemoveSell()}>
            <div className="offer-btn-back">REMOVE FROM SALE</div>
          </button>
        )}
      </div>
      <SellModal
        isModalOpen={sellModalIsOpen && isConnected && !!userSession}
        setModalOpen={setSellModalIsOpen}
        loadData={loadData}
        serviceFee={collectionData?.creatorFee}
      />
      <RemoveSaleModal
        isModalOpen={removeSaleModalIsOpen && isConnected && !!userSession}
        setModalOpen={setRemoveSaleModalIsOpen}
        loadData={loadData}
        nftData={nftData}
      />
    </>
  );
}
