import Modal from "react-modal";
import { useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import { ethers } from "ethers";

export default function AcceptOfferModal({
  isModalOpen,
  acceptInfo,
  serviceFee,
  closeModal,
  approveOffer,
  buttonLoading,
}) {
  const { tokenId } = useParams();
  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={isModalOpen}
      onRequestClose={() => {
        !buttonLoading && closeModal();
      }}
      contentLabel="Accept Offer Modal"
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "overlay-after",
        beforeClose: "overlay-before",
      }}
      className={{
        base: "content-base",
        afterOpen: "content-after",
        beforeClose: "content-before",
      }}
      closeTimeoutMS={500}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="modal-title2">Accept Offer</h2>
        <div className="close" onClick={closeModal}>
          <i className="fa close-icon">&#xf00d;</i>
        </div>
      </div>
      <p className="modal-description text-white">
        You are about to accept an offer #{tokenId} from{" "}
        {acceptInfo.offerer.substring(2, 8).toUpperCase()}
      </p>
      <h3 className="modal-big-text">
        {ethers.utils.formatEther(acceptInfo.price)} WETH
      </h3>
      <div className="modal-line"></div>
      <div className="row-item">
        <p className="modal-description m-0">Service fee</p>
        <p className="count">
          {(
            ethers.utils.formatEther(acceptInfo.price) *
            (serviceFee / 100)
          ).toFixed(4)}{" "}
          WETH
        </p>
      </div>
      <div className="row-item">
        <p className="modal-description m-0">Total potential earnings</p>
        <p className="count">
          {(
            ethers.utils.formatEther(acceptInfo.price) *
            ((100 - serviceFee) / 100)
          ).toFixed(4)}{" "}
          WETH
        </p>
      </div>
      <button
        className="modal-ok-btn d-flex align-items-center justify-content-center"
        disabled={buttonLoading}
        onClick={() => approveOffer(acceptInfo)}
      >
        {buttonLoading ? (
          <ReactLoading
            className="step-loading"
            type="spinningBubbles"
            color="#ffffff"
            height={30}
            width={30}
          />
        ) : (
          "ACCEPT BID"
        )}
      </button>
      <button className="modal-cancel-btn" onClick={closeModal}>
        CANCEL
      </button>
    </Modal>
  );
}
