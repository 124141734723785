import {
  prepareWriteContract,
  writeContract,
  waitForTransaction,
} from "@wagmi/core";
import showSwal from "../utils/showSwal";

const MBH = require("./abi/MBH.json");

export const transferNFTWeb3 = async (
  address,
  nftAddress,
  receiverAddress,
  tokenId
) => {
  try {
    const config = await prepareWriteContract({
      address: nftAddress,
      abi: MBH.abi,
      functionName: "transferFrom",
      args: [address, receiverAddress, tokenId],
    });
    const { hash } = await writeContract(config);
    const transaction = await waitForTransaction({ hash });
    return { success: true, hash: transaction.transactionHash };
  } catch (err) {
    if (err.code !== 4001)
      showSwal(err?.error?.message || err.message, "warning");
    return;
  }
};
