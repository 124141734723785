import { useState } from "react";
import { useParams } from "react-router-dom";
import { ethers } from "ethers";
import Modal from "react-modal";
import { useAccount } from "wagmi";
import useWallet from "../../hooks/useWallet";
import { transferNFTWeb3 } from "../../web3/nft";
import { updateListing } from "../../api";
import showSwal from "../../utils/showSwal";
import ReactLoading from "react-loading";

export default function TransferModal({
  isModalOpen,
  setModalOpen,
  nftData,
  loadData
}) {
  const { nftAddress, tokenId } = useParams();
  const { address, isConnected } = useAccount();
  const { handleConnectWallet, userSession } = useWallet();
  const [receiverAddress, setReceiverAddress] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const transferToken = async () => {
    setButtonLoading(true);
    try {
      if (
        !isConnected ||
        !userSession ||
        userSession.address.toLowerCase() !== address.toLowerCase()
      ) {
        await handleConnectWallet();
      }

      const isTransferred = await transferNFTWeb3(
        address,
        nftAddress,
        receiverAddress,
        tokenId
      );

      if (isTransferred && isTransferred.success === true) {
        if (nftData.price) {
          await updateListing({
            address,
            nftAddress,
            tokenId,
            price: nftData?.price,
            live: false
          });
        }
        loadData();
        closeModal();
        showSwal(
          nftData.name + " #" + tokenId + " has been successfully transferred!",
          "success"
        );
      }
    } catch (err) {
      if (err.code !== 4001)
        showSwal(err?.error?.message || err.message, "warning");
      return;
    }
    setButtonLoading(false);
  };

  const closeModal = () => {
    setButtonLoading(false);
    setModalOpen(false);
  };
  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={isModalOpen}
      onRequestClose={() => {
        !buttonLoading && closeModal();
      }}
      contentLabel="Transaction Modal"
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "overlay-after",
        beforeClose: "overlay-before"
      }}
      className={{
        base: "content-base",
        afterOpen: "content-after",
        beforeClose: "content-before"
      }}
      closeTimeoutMS={500}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="modal-title2">Transfer token</h2>
        <div className="close" onClick={closeModal}>
          <i className="fa close-icon">&#xf00d;</i>
        </div>
      </div>
      <p className="modal-description">
        You can transfer tokens from your address to another.
      </p>
      <h2 className="modal-title2">Receiver address</h2>
      <input
        type="text"
        placeholder="Paste address"
        className="modal-address mb-2"
        value={receiverAddress}
        onChange={(e) => {
          setReceiverAddress(e.target.value);
        }}
      />
      {receiverAddress && !ethers.utils.isAddress(receiverAddress) && (
        <p className="red mt-0">
          <i className="fa fa-exclamation-triangle"></i>Please input the correct
          address!
        </p>
      )}
      <button
        className="modal-ok-btn d-flex align-items-center justify-content-center"
        disabled={
          !receiverAddress ||
          !ethers.utils.isAddress(receiverAddress) ||
          buttonLoading
        }
        onClick={() => transferToken()}
      >
        {buttonLoading ? (
          <ReactLoading
            className="step-loading"
            type="spinningBubbles"
            color="#ffffff"
            height={30}
            width={30}
          />
        ) : (
          "CONTINUE"
        )}
      </button>
      <button className="modal-cancel-btn" onClick={closeModal}>
        CANCEL
      </button>
    </Modal>
  );
}
