import { Home } from "./pages/home/index.jsx";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import "./App.css";
import { Navbar } from "./components/navbar";
import { Profile } from "./pages/profile/index.jsx";
import { EditProfile } from "./pages/profile/edit.jsx";
import { Detail } from "./pages/detail/index.jsx";

const App = () => {
  return (
    <Router>
      <div className="App">
        <ScrollToTop smooth component={<i className="fa fa-arrow-up"></i>} />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/profile/:userAddress" element={<Profile />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/edit_profile" element={<EditProfile />} />
          <Route
            exact
            path="/detail/:nftAddress/:tokenId"
            element={<Detail />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
