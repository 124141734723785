import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import banner from "../../assets/images/cover.png";
import avatar from "../../assets/images/avatar.png";
import copy_icon from "../../assets/images/copy-icon.png";
import global from "../../assets/images/global.png";
/* import twitter_icon from "../../assets/images/twitter.png";
import instagram_icon from "../../assets/images/instagram.png";
import facebook_icon from "../../assets/images/facebook.png"; */
import "./profile.css";
import rarity_icon from "../../assets/images/rarity.webp";
import { HashLink } from "react-router-hash-link";
import { useAccount } from "wagmi";
import { fetchNftByWallet, fetchUserInfo } from "../../api";
import { ethers } from "ethers";
import useWallet from "../../hooks/useWallet";
import useCopyClip from "../../hooks/useCopyClip";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

export const Profile = () => {
    const navigate = useNavigate();
    const { copyStatus, copyToClipboard } = useCopyClip();
    const gridSelected = useSelector((state) => state.grid.selected);
    const { handleConnectWallet, userSession } = useWallet();
    const [filterOption, setFilterOption] = useState(1);
    const [viewItems, setViewItems] = useState([]);
    const [items, setItems] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [walletAddress, setWalletAddress] = useState("");
    const [pageLoading, setPageLoading] = useState(false);
    const { address } = useAccount();
    const { userAddress } = useParams();

    useEffect(() => {
        const fetchData = async (wallet) => {
            setPageLoading(true);
            setUserInfo(await fetchUserInfo({ address: wallet }));
            const res = await fetchNftByWallet({ address: wallet });
            if (res) {
                setViewItems(res);
                setItems(res);
            } else {
                setViewItems([]);
                setItems([]);
            }
            setPageLoading(false);
        }

        if (userAddress) {
            setWalletAddress(userAddress);
            fetchData(userAddress);
        } else if (address) {
            setWalletAddress(address)
            fetchData(address)
        } else {
            setViewItems([]);
            setItems([]);
            setUserInfo({});
        }
    }, [userAddress, address]);

    const handleFilter = (filterId) => {
        switch (filterId) {
            case 1:
                setViewItems(items);
                break;
            case 2:
                setViewItems(items.filter(item => !!item.listingPrice));
                break;
            case 3:
                setViewItems(items.filter(item => !!item.bestOfferPrice));
                break;
            case 4:
                setViewItems(items.filter(item => !!item.bestOfferPrice));
                break;
            default:
                setViewItems(items);
        }
        setFilterOption(filterId);
    }

    const handleEditProfile = async () => {
        if (!userSession || userSession.address.toLowerCase() !== address.toLowerCase()) {
            await handleConnectWallet();
        }
        navigate("/edit_profile");
    }

    return (
        <div className="profile">
            <div className="profile-banner">
                <img src={banner} alt="banner" />
                <div className="btn-group">
                    {/* <button>EDIT COVER PHOTO <i className="fas fa-image"></i></button> */}
                    {address?.toLowerCase() === walletAddress?.toLowerCase() && (
                        <button onClick={() => handleEditProfile()}>EDIT PROFILE <i className="fas fa-edit"></i></button>
                    )}
                </div>
            </div>
            <div className="custom-container">
                <div className="d-flex w-100 profile-main">
                    <div className="profile-info">
                        <img src={avatar} alt="alt" className="avatar" />
                        <h1 className="user-id">{userInfo?.name || "Unnamed"}</h1>
                        <h2 className="wallet-address" onClick={() => copyToClipboard(walletAddress)}>
                            {!!walletAddress &&
                                ` ${walletAddress.substring(0, 15)}...${walletAddress.substring(
                                    walletAddress.length - 4
                                )}`}
                            {copyStatus ? (<i className="fas fa-check"></i>) : (
                                <img src={copy_icon} alt="copy icon" />
                            )}

                        </h2>
                        <p className="user-description">{userInfo?.bio || ""}</p>
                        {!!userInfo?.websiteLink && (
                            <a className="user-site" href={userInfo?.websiteLink} target="_blank" rel="noreferrer"><img src={global} alt="global" /> {userInfo?.websiteLink}</a>
                        )}

                        {/* <button className="follow-btn">FOLLOW</button> */}
                        {/* <div className="user-socials">
                            <img src={twitter_icon} alt="twitter icon" />
                            <img src={instagram_icon} alt="instagram icon" />
                            <img src={facebook_icon} alt="facebook icon" />
                        </div> */}
                    </div>
                    <div className="nfts">
                        <div className="profile-sort">
                            <button className={"profile-sort-option " + (filterOption === 1 ? "active" : "")} onClick={() => handleFilter(1)} disabled={pageLoading}>All</button>
                            <button className={"profile-sort-option " + (filterOption === 2 ? "active" : "")} onClick={() => handleFilter(2)} disabled={pageLoading}>On sale</button>
                            <button className={"profile-sort-option " + (filterOption === 3 ? "active" : "")} onClick={() => handleFilter(3)} disabled={pageLoading}>Has offer</button>
                            {/* <button className={"profile-sort-option " + (filterOption === 4 ? "active" : "")} onClick={() => setFilterOption(4)} disabled={pageLoading}>Likes</button> */}
                            {/*  <button className={"profile-sort-option " + (filterOption === 4 ? "active" : "")} onClick={() => setFilterOption(4)}>Likes</button> */}
                        </div>
                        {pageLoading ? (
                            <button className="load-btn">
                                <ReactLoading className="step-loading" type="spinningBubbles" color="#ffffff" height={30} width={30} />
                                LOAD MORE
                            </button>
                        ) : (
                            viewItems.length > 0 ? (
                                <div className={
                                    (gridSelected === 0 ? "big-grid" : "small-grid") + " nfts-grid"}
                                >
                                    {viewItems?.map((item, index) => (
                                        <div className="nft-item" key={index}>
                                            <HashLink
                                                to={`/detail/${item.token_address}/${item.token_id}#`}
                                                smooth
                                                className="text-decoration-none"
                                            >
                                                <div className="overflow-hidden">
                                                    {item.normalized_metadata.image &&
                                                        item.normalized_metadata.image.includes(".m4v") ? (
                                                        <video
                                                            src={
                                                                item.normalized_metadata.image + "?stream=true" ||
                                                                "/assets/images/default/" + item.symbol + ".mp4"
                                                            }
                                                            controls
                                                            muted
                                                            className="nft"
                                                        />
                                                    ) : (<img
                                                        src={item.media?.media_collection?.high?.url ||
                                                            (!!item.normalized_metadata.image &&
                                                                item.normalized_metadata.image + "?img-width=380") ||
                                                            "/assets/images/default/" + item.symbol + ".gif"}
                                                        className="nft"
                                                        alt="nft" />)
                                                    }
                                                </div>
                                                <div className="nft-content">
                                                    <div className="">
                                                        <p className="nft-id text-start mb-1 mt-1">
                                                            {item?.name} #{item?.token_id}
                                                        </p>
                                                        <div className="clearfix"></div>
                                                        {!!item.listingPrice && (
                                                            <p className="float-start nft-price mt-1">
                                                                {ethers.utils.formatEther(item.listingPrice)} ETH
                                                            </p>
                                                        )}
                                                        {!!item.rank && (
                                                            <>
                                                                <p
                                                                    className="nft-rank float-end d-flex align-items-center"
                                                                    id={"rank" + item.rank}
                                                                >
                                                                    <img src={rarity_icon} style={{ width: "15px" }} className="me-1" alt="rarity-icon" />{item.rank}
                                                                </p>
                                                                <Tooltip
                                                                    anchorId={"rank" + item.rank}
                                                                    place="top"
                                                                    content={"Rarity rank: " + item.rank + " / 8888"}
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="clearfix" />
                                                    {!!item.bestOfferPrice && (
                                                        <div className="text-end offer-price">
                                                            Best offer: {ethers.utils.formatEther(item.bestOfferPrice)} ETH
                                                        </div>
                                                    )}
                                                </div>

                                            </HashLink>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <>
                                    <h4 className="mt-5">No items.</h4>
                                </>
                            )
                        )}

                    </div>
                </div>
            </div >
        </div >
    );
}