const localDate = (dateData) => {
  var localDate = new Date(dateData).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  return localDate;
};

export default localDate;
