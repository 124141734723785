import {
  prepareWriteContract,
  writeContract,
  waitForTransaction,
} from "@wagmi/core";
import showSwal from "../utils/showSwal";

const market = require("./abi/MBHMarketplace.json");

export const listItemWeb3 = async (
  nftAddress,
  tokenId,
  payMethod,
  payToken,
  price,
  deadLine
) => {
  try {
    const config = await prepareWriteContract({
      address: process.env.REACT_APP_MARKET_ADDRESS,
      abi: market.abi,
      functionName: "listItem",
      args: [nftAddress, tokenId, payMethod, payToken, price, deadLine],
    });
    const { hash } = await writeContract(config);
    const transaction = await waitForTransaction({ hash });
    return { success: true, hash };
  } catch (err) {
    if (err.code !== 4001)
      showSwal(err?.error?.message || err.message, "warning");
    return;
  }
};

export const updatePriceWeb3 = async (
  nftAddress,
  tokenId,
  payMethod,
  payToken,
  newPrice
) => {
  try {
    const config = await prepareWriteContract({
      address: process.env.REACT_APP_MARKET_ADDRESS,
      abi: market.abi,
      functionName: "updateListing",
      args: [nftAddress, tokenId, payMethod, payToken, newPrice],
    });
    const { hash } = await writeContract(config);
    const transaction = await waitForTransaction({ hash });
    return { success: true, hash: transaction.transactionHash };
  } catch (err) {
    if (err.code !== 4001)
      showSwal(err?.error?.message || err.message, "warning");
    return;
  }
};

export const cancelListingWeb3 = async (nftAddress, tokenId) => {
  try {
    const config = await prepareWriteContract({
      address: process.env.REACT_APP_MARKET_ADDRESS,
      abi: market.abi,
      functionName: "cancelListing",
      args: [nftAddress, tokenId],
    });
    const { hash } = await writeContract(config);
    const transaction = await waitForTransaction({ hash });
    return { success: true, hash: transaction.transactionHash };
  } catch (err) {
    if (err.code !== 4001)
      showSwal(err?.error?.message || err.message, "warning");
    return;
  }
};

export const buyItemWeb3 = async (
  address,
  nftAddress,
  tokenId,
  payToken,
  owner,
  price
) => {
  try {
    const config = await prepareWriteContract({
      address: process.env.REACT_APP_MARKET_ADDRESS,
      abi: market.abi,
      functionName: "buyItem",
      args: [nftAddress, tokenId, payToken, owner],
      overrides: {
        from: address,
        value: price,
      },
    });
    const { hash } = await writeContract(config);
    const transaction = await waitForTransaction({ hash });
    return { success: true, hash: transaction.transactionHash };
  } catch (err) {
    if (err.code !== 4001)
      showSwal(err?.error?.message || err.message, "warning");
    return;
  }
};
