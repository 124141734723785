import { useState } from "react";
import Modal from "react-modal";
import { cancelListingWeb3 } from "../../web3/listing";
import { useParams } from "react-router-dom";
import { updateListing } from "../../api";
import { useAccount } from "wagmi";
import ReactLoading from "react-loading";
import showSwal from "../../utils/showSwal";
import useWallet from "../../hooks/useWallet";

export default function RemoveSaleModal({
  isModalOpen,
  setModalOpen,
  loadData,
  nftData,
}) {
  const { address, isConnected } = useAccount();
  const { userSession, handleConnectWallet } = useWallet();
  const { nftAddress, tokenId } = useParams();
  const [buttonLoading, setButtonLoading] = useState(false);

  const removeFromSale = async () => {
    setButtonLoading(true);
    try {
      if (
        !isConnected ||
        !userSession ||
        userSession.address.toLowerCase() !== address.toLowerCase()
      ) {
        await handleConnectWallet();
      }
      const isCancelListing = await cancelListingWeb3(nftAddress, tokenId);
      if (isCancelListing) {
        const isUpdateListing = await updateListing({
          nftAddress,
          tokenId,
          payToken: nftData?.payToken,
          payMethod: nftData?.payMethod,
          deadLine: nftData?.deadline,
          creatorAddress: nftData?.owner_of,
          price: nftData?.price,
          live: 0,
        });
        if (isUpdateListing) {
          await loadData();
          closeModal();
          showSwal("Successfully removed from sale!", "success");
        }
      }
    } catch (err) {
      console.log(err);
      if (err.code === 4001) showSwal(err.message, "warning");
      else showSwal("Error happened while processing.", "warning");
    }
    setButtonLoading(false);
  };

  const closeModal = () => {
    setModalOpen(false);
    setButtonLoading(false);
  };

  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={isModalOpen}
      onRequestClose={() => {
        !buttonLoading && closeModal();
      }}
      contentLabel="Remove Sale Modal"
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "overlay-after",
        beforeClose: "overlay-before",
      }}
      className={{
        base: "content-base",
        afterOpen: "content-after",
        beforeClose: "content-before",
      }}
      closeTimeoutMS={500}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="modal-title2">Remove from sale</h2>
        <div className="close" onClick={closeModal}>
          <i className="fa close-icon">&#xf00d;</i>
        </div>
      </div>
      <p className="modal-description">
        Do you really want to remove your item from sale? You can put it on sale
        anytime
      </p>
      <button
        className="modal-ok-btn d-flex align-items-center justify-content-center"
        onClick={() => removeFromSale()}
        disabled={buttonLoading}
      >
        {buttonLoading ? (
          <ReactLoading
            className="step-loading"
            type="spinningBubbles"
            color="#ffffff"
            height={30}
            width={30}
          />
        ) : (
          "REMOVE NOW"
        )}
      </button>
      <button className="modal-cancel-btn" onClick={closeModal}>
        CANCEL
      </button>
    </Modal>
  );
}
