import { useState } from "react";
import button_icon from "../../assets/images/mbh-icon.png";
import { useAccount, useBalance } from "wagmi";
import useWallet from "../../hooks/useWallet";
import { MakeOfferModal } from "../modal";
import CheckoutModal from "../modal/CheckoutModal";

export default function Buyer({ nftData, loadData, collectionData }) {
  const { handleConnectWallet, userSession } = useWallet();
  const { address, isConnected } = useAccount();
  const { data: balance } = useBalance({ address });

  const [checkoutModalIsOpen, setCheckoutModalIsOpen] = useState(false);
  const [offerModalIsOpen, setOfferModalIsOpen] = useState(false);

  const handleOffer = async () => {
    if (
      !isConnected ||
      !userSession ||
      userSession.address.toLowerCase() !== address.toLowerCase()
    ) {
      await handleConnectWallet();
    }
    setOfferModalIsOpen(true);
  };

  const handlePurchse = async () => {
    if (
      !isConnected ||
      !userSession ||
      userSession.address.toLowerCase() !== address.toLowerCase()
    ) {
      await handleConnectWallet();
    }
    setCheckoutModalIsOpen(true);
  };
  const closeModal = () => {
    setCheckoutModalIsOpen(false);
    setOfferModalIsOpen(false);
  };

  return (
    <>
      <div className="button-group mt-4">
        {!!nftData.price && (
          <button className="buy-btn" onClick={() => handlePurchse()}>
            <img src={button_icon} alt="button-icon" />
            BUY NOW
          </button>
        )}
        <button className="offer-btn" onClick={handleOffer}>
          <div className="offer-btn-back">MAKE OFFER</div>
        </button>
      </div>
      <MakeOfferModal
        isModalOpen={offerModalIsOpen && isConnected && !!userSession}
        loadData={loadData}
        closeModal={closeModal}
      />
      {!!nftData && nftData.price && balance && (
        <CheckoutModal
          isModalOpen={checkoutModalIsOpen && isConnected && !!userSession}
          closeModal={closeModal}
          nftData={nftData}
          loadData={loadData}
          serviceFee={collectionData?.creatorFee}
        />
      )}
    </>
  );
}
